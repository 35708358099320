"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ServerToFarmType = void 0;
var ServerToFarmType;

(function (ServerToFarmType) {
  ServerToFarmType["AcOffCommand"] = "AcOffCommand";
  ServerToFarmType["AcOnCommand"] = "AcOnCommand";
  ServerToFarmType["BooleanUpdate"] = "BooleanUpdate";
  ServerToFarmType["ChangeLightLevelCommand"] = "ChangeLightLevelCommand";
  ServerToFarmType["CloseConnectionRequest"] = "CloseConnectionRequest";
  ServerToFarmType["CommandChangeValveState"] = "CommandChangeValveState";
  ServerToFarmType["CommandLineCommand"] = "CommandLineCommand";
  ServerToFarmType["DoorCloseCommand"] = "DoorCloseCommand";
  ServerToFarmType["DoorOpenCommand"] = "DoorOpenCommand";
  ServerToFarmType["DockCommand"] = "DockCommand";
  ServerToFarmType["DrainCloseCommand"] = "DrainCloseCommand";
  ServerToFarmType["DrainOpenCommand"] = "DrainOpenCommand";
  ServerToFarmType["UndockCommand"] = "UndockCommand";
  ServerToFarmType["DriveCommand"] = "DriveCommand";
  ServerToFarmType["DriveCommand2"] = "DriveCommand2";
  ServerToFarmType["FansOffCommand"] = "FansOffCommand";
  ServerToFarmType["FansOnCommand"] = "FansOnCommand";
  ServerToFarmType["GantryFindFarXCommand"] = "GantryFindFarXCommand";
  ServerToFarmType["GantryFindFarYCommand"] = "GantryFindFarYCommand";
  ServerToFarmType["GantryFindFarZCommand"] = "GantryFindFarZCommand";
  ServerToFarmType["GantryGoToCommand"] = "GantryGoToCommand";
  ServerToFarmType["GantryHardStopCommand"] = "GantryHardStopCommand";
  ServerToFarmType["GantryHomeCommand"] = "GantryHomeCommand";
  ServerToFarmType["GantryMoveByCommand"] = "GantryMoveByCommand";
  ServerToFarmType["GantryStopCommand"] = "GantryStopCommand";
  ServerToFarmType["GetExperimentsResponse"] = "GetExperimentsResponse";
  ServerToFarmType["InitializeCommand"] = "InitializeCommand";
  ServerToFarmType["LiftCommand"] = "LiftCommand";
  ServerToFarmType["LightsOffCommand"] = "LightsOffCommand";
  ServerToFarmType["LightsOnCommand"] = "LightsOnCommand";
  ServerToFarmType["MessageRequest"] = "MessageRequest";
  ServerToFarmType["NavigateCommand"] = "NavigateCommand";
  ServerToFarmType["Pong"] = "Pong";
  ServerToFarmType["PumpOffCommand"] = "PumpOffCommand";
  ServerToFarmType["PumpOnCommand"] = "PumpOnCommand";
  ServerToFarmType["PumpAutoCommand"] = "PumpAutoCommand";
  ServerToFarmType["PumpToEmptyCommand"] = "PumpToEmptyCommand";
  ServerToFarmType["RebootCommand"] = "RebootCommand";
  ServerToFarmType["ReturnFarmData"] = "ReturnFarmData";
  ServerToFarmType["ResetDeadReckoningCommand"] = "ResetDeadReckoningCommand";
  ServerToFarmType["ResetFlowInCommand"] = "ResetFlowInCommand";
  ServerToFarmType["ResetFlowOutCommand"] = "ResetFlowOutCommand";
  ServerToFarmType["ShutDownCommand"] = "ShutDownCommand";
  ServerToFarmType["UmbilicalGantryHomeCommand"] = "UmbilicalGantryHomeCommand";
  ServerToFarmType["UpdateFirmwareCommand"] = "UpdateFirmwareCommand";
  ServerToFarmType["ValveCloseCommand"] = "ValveCloseCommand";
  ServerToFarmType["ValveOpenCommand"] = "ValveOpenCommand";
  ServerToFarmType["WhoAreYouRequest"] = "WhoAreYouRequest";
  ServerToFarmType["WhoAmIResponse"] = "WhoAmIResponse";
})(ServerToFarmType = exports.ServerToFarmType || (exports.ServerToFarmType = {}));