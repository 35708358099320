import { ServerToFarmType } from 'model/src/dataflowprotocol/servertofarm/ServerToFarmType';
import TimerTemplate, {
  TimerTemplateUpdateType,
} from 'model/src/timer/TimerTemplate';
import React, { useContext } from 'react';
import { MINUTES_PER_HOUR } from 'services/src/common/Clock';
import styled from 'styled-components';

import { cssVar } from '../..';
import ClassNames from '../../ClassNames';
import { getActionLabel } from '../../farm-ui/windows/SchedulerWindow';
import InjectionContext, {
  InjectionContextType,
} from '../../injection/InjectionContext';
import WindowType from '../window/WindowTypes';
import TimerTemplateBlock from './TimerTemplateBlock';

const LAYER_MINIMUM_GAP_MINUTES = 45;

export enum ScheduleTimerType {
  LIGHTS_ON = 'LIGHTS_ON',
  LIGHTS_OFF = 'LIGHTS_OFF',
  VALVE_OPEN = 'VALVE_OPEN',
  VALVE_CLOSE = 'VALVE_CLOSE',
}

export function getTimerLabel(timer: ScheduleTimerType) {
  switch (timer) {
    case ScheduleTimerType.LIGHTS_ON:
      return 'Lights On';
    case ScheduleTimerType.LIGHTS_OFF:
      return 'Lights Off';
    case ScheduleTimerType.VALVE_OPEN:
      return 'Open Valve';
    case ScheduleTimerType.VALVE_CLOSE:
      return 'Close Valve';
  }
}

const TimerLayer = styled.div`
  position: absolute;
  display: inline-block;
  top: 0;
  bottom: 0;
  min-height: var(--scheduleCalendarBackgroundHeight);
  width: calc(var(--schedulerWindowWidth) - var(--spacingExtraLarge));
  z-index: 102;
  background: transparent;
`;

const getTimeInPixelsFromMinutes = minutes => {
  const MINUTE_TO_PIXEL_MULTIPLIER =
    Number(cssVar('--spacingExtraLarge').replace('px', '')) / MINUTES_PER_HOUR;
  return (minutes * MINUTE_TO_PIXEL_MULTIPLIER).toString() + 'px';
};

const ScheduleTimerTemplateFormatter = props => {
  const injectionContext: InjectionContextType = useContext(InjectionContext);
  const coreLogger = injectionContext.coreLogger;
  if (props.target === '') {
    return <></>;
  }
  const getTimerVisuals = (
    timersInMinutes: {
      timerTemplate: TimerTemplate;
      actionType: ServerToFarmType;
      timeInMinutes: number;
      id: string;
    }[]
  ) => {
    const timers: {
      timerTemplate: TimerTemplate;
      actionType: ServerToFarmType;
      timeInMinutes: number;
      id: string;
    }[][] = [];
    timersInMinutes.sort((a, b) => {
      if (a.timeInMinutes - b.timeInMinutes === 0) {
        return a.actionType.localeCompare(b.actionType);
      } else {
        return a.timeInMinutes - b.timeInMinutes;
      }
    });
    let timersInMinutesCopy = [...timersInMinutes];

    while (timersInMinutesCopy.length !== 0) {
      const nextLayer: {
        timerTemplate: TimerTemplate;
        actionType: ServerToFarmType;
        timeInMinutes: number;
        id: string;
      }[] = [];

      for (let timer of timersInMinutesCopy) {
        if (nextLayer.length === 0) {
          nextLayer.push(timer);
          timersInMinutes = timersInMinutes.filter(
            item => !(item.timerTemplate.id === timer.timerTemplate.id)
          );
        } else if (
          Math.abs(
            timer.timeInMinutes - nextLayer[nextLayer.length - 1].timeInMinutes
          ) >= LAYER_MINIMUM_GAP_MINUTES
        ) {
          nextLayer.push(timer);
          timersInMinutes = timersInMinutes.filter(
            item => !(item.timerTemplate.id === timer.timerTemplate.id)
          );
        }
      }
      timersInMinutesCopy = timersInMinutes;
      timers.push(nextLayer);
    }
    return timers;
  };

  const getFlattenedTimerVisuals = (
    timerTemplateVisuals: {
      timerTemplate: TimerTemplate;
      actionType: ServerToFarmType;
      timeInMinutes: number;
      id: string;
    }[][]
  ) => {
    const flattenedTimers: any[] = [];
    timerTemplateVisuals.map((innerTimers, index) =>
      innerTimers.map(item => {
        flattenedTimers.push({
          timerTemplate: item.timerTemplate,
          left: index * 85,
          color: item.timerTemplate.isEnabled
            ? cssVar('--systemGreen')
            : cssVar('--systemRed'),
          text: getActionLabel(item.actionType, coreLogger),
          time: getTimeInPixelsFromMinutes(item.timeInMinutes),
          id: item.id,
        });
      })
    );
    return flattenedTimers;
  };

  if (props.currentDailyTimerTemplates) {
    const timerTemplateVisuals = getTimerVisuals(
      props.currentDailyTimerTemplates
    );
    const flattenedTimerVisuals = getFlattenedTimerVisuals(
      timerTemplateVisuals
    );
    return (
      <TimerLayer>
        {flattenedTimerVisuals.map(item => (
          <TimerTemplateBlock
            showContextMenu={props.showContextMenu}
            hideContextMenu={props.hideContextMenu}
            setModal={props.setModal}
            timerTemplate={item.timerTemplate}
            key={item.id}
            className={ClassNames.footerText}
            left={item.left}
            color={item.color}
            text={item.text}
            time={item.time}
          />
        ))}
      </TimerLayer>
    );
  } else {
    return <></>;
  }
};

export default ScheduleTimerTemplateFormatter;
