import {
  combine,
  create,
  fullString,
  toAddress
} from 'model/src/common/CloudProduceAddressUtility';
import { Axis, UmbilicalSubsystem } from 'model/src/common/Systems';
import { Data } from 'model/src/dataflowprotocol/Datum';
import {
  convert,
  NumericalReading,
  Unit
} from 'model/src/series/NumericalReading';
import { SeriesType } from 'model/src/series/SeriesTypes';
import { ValveStateReading } from 'model/src/series/ValveStateReading';
import { AcState } from 'model/src/status/AcState';
import { ValveState } from 'model/src/status/ValveState';
import React, {
  Suspense,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';

import { cssVar } from '../../../..';
import { HELVETIKER_REGULAR_FONT } from '../../../../App';
import ErrorBoundary from '../../../ErrorBoundary';
import {
  ControllerContext,
  ControllerContextType
} from '../../../farmviewer/context/controllercontext/ControllerContext';
import Box from '../../common/Box';
import Text from '../../common/Text';
import UmbilicalAcIndicatorLight from './UmbilicalAcIndicatorLight';
import UmbilicalGantryXAxisBeam from './UmbilicalGantryXAxisBeam';
import UmbilicalGantryXAxisSlide from './UmbilicalGantryXAxisSlide';
import UmbilicalGantryYAxisBeam from './UmbilicalGantryYAxisBeam';
import UmbilicalGantryYAxisSlide from './UmbilicalGantryYAxisSlide';
import UmbilicalGantryZAxisBeam from './UmbilicalGantryZAxisBeam';
import UmbilicalGantryZAxisSlide from './UmbilicalGantryZAxisSlide';
import UmbilicalMale from './UmbilicalMale';
import ValveIndicatorLight from './UmbilicalValveIndicatorLight';

export interface UmbilicalProps {
  url: string;
  inspectionContext;
  dataMap;
  globals;
  objectMap;
  online: boolean;
  onClick;
  showMenu;
  parentHovered: boolean;
  parentUnderInspection: boolean;
}

export default function Umbilical(props: UmbilicalProps) {
  const controllerContext: ControllerContextType = useContext(
    ControllerContext
  );
  const [hovered, setHover] = useState(false);
  const underInspection = false; // props.inspectionContext.inspectionMap.get(props.url).underInspection;
  const group = useRef();

  const xUrl = fullString(
    combine(
      toAddress(props.url),
      create([UmbilicalSubsystem.GANTRY, Axis.X, SeriesType.POSITION])
    )
  );
  const xData: Data<NumericalReading> = props.dataMap.all.get(xUrl);
  const xCm = xData.data[0].reading.value / convert(Unit.CM, Unit.MM);

  const yUrl = fullString(
    combine(
      toAddress(props.url),
      create([UmbilicalSubsystem.GANTRY, Axis.Y, SeriesType.POSITION])
    )
  );
  const yData: Data<NumericalReading> = props.dataMap.all.get(yUrl);
  const yCm = yData.data[0].reading.value / convert(Unit.CM, Unit.MM);

  const zUrl = fullString(
    combine(
      toAddress(props.url),
      create([UmbilicalSubsystem.GANTRY, Axis.Z, SeriesType.POSITION])
    )
  );
  const zData: Data<NumericalReading> = props.dataMap.all.get(zUrl);
  const zCm = zData.data[0].reading.value / convert(Unit.CM, Unit.MM);

  const isAcOn =
    props.dataMap.all.get(
      fullString(combine(toAddress(props.url), create([SeriesType.AC_STATE])))
    ).data[0].reading.value === AcState.ON;

  const valveStateReading: ValveStateReading = props.dataMap.all.get(
    fullString(combine(toAddress(props.url), create([SeriesType.VALVE_STATE])))
  ).data[0].reading;

  const isValveOpen =
    (valveStateReading.value
      ? valveStateReading.value
      : valveStateReading.valveState) === ValveState.OPEN; // TODO(philipp), ISSUE(1): Remove refs to valveState after migration

  const onPointerOver = controllerContext.dragMute(event => {
    setHover(true);
    event.stopPropagation();
  }, controllerContext);

  useEffect(() => {
    props.objectMap.set(props.url, {
      url: props.url,
      model: group.current,
    });
    return () => {
      props.objectMap.delete(props.url);
    };
  });

  const textOptions = {
    font: HELVETIKER_REGULAR_FONT.font,
    size: 5,
    height: 1,
  };

  return (
    <ErrorBoundary>
      <group
        ref={group}
        onClick={e => {
          if (!controllerContext.keys.has('Alt')) {
            props.onClick(props.url);
            e.stopPropagation();
          } else {
            props.showMenu(props.url);
            e.stopPropagation();
          }
        }}
        onContextMenu={e => {
          props.showMenu(props.url);
          e.stopPropagation();
        }}
        onPointerOver={onPointerOver}
        onPointerOut={event => {
          setHover(false);
          event.stopPropagation();
        }}
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}>
        <ErrorBoundary>
          <Suspense fallback={<Box />}>
            <group>
              <Text
                position={[0, 0, 110]}
                rotation={[Math.PI / 2, 0, 0]}
                text={props.url}
                textOptions={textOptions}
                color={cssVar('--cloud-produce-international-orange')}
              />
              <UmbilicalAcIndicatorLight
                isOn={isAcOn}
                position={[-50, -50, 140]}
              />
              <ValveIndicatorLight
                isOn={isValveOpen}
                position={[-50, -50, 120]}
              />
              <UmbilicalGantryZAxisSlide
                online={props.online}
                parentHovered={hovered || props.parentHovered}
                parentUnderInspection={
                  underInspection || props.parentUnderInspection
                }
              />
              <group position={[0, 0, zCm]}>
                <UmbilicalGantryZAxisBeam
                  online={props.online}
                  parentHovered={hovered || props.parentHovered}
                  parentUnderInspection={
                    underInspection || props.parentUnderInspection
                  }
                />
                <group
                  position={[25.635, -56, 87.4061]}
                  rotation={[0, -Math.PI / 2, 0]}>
                  <UmbilicalGantryXAxisBeam
                    online={props.online}
                    parentHovered={hovered || props.parentHovered}
                    parentUnderInspection={
                      underInspection || props.parentUnderInspection
                    }
                  />
                  <group position={[0, 0, xCm]}>
                    <group position={[0.086, 0, -20]}>
                      <UmbilicalGantryXAxisSlide
                        online={props.online}
                        parentHovered={hovered || props.parentHovered}
                        parentUnderInspection={
                          underInspection || props.parentUnderInspection
                        }
                      />
                      <group
                        position={[4.3558, -45.27, 45.27]}
                        rotation={[-Math.PI / 2, 0, Math.PI]}>
                        <UmbilicalGantryYAxisSlide
                          online={props.online}
                          parentHovered={hovered || props.parentHovered}
                          parentUnderInspection={
                            underInspection || props.parentUnderInspection
                          }
                        />
                        <group position={[0, 0, -yCm]}>
                          <UmbilicalGantryYAxisBeam
                            online={props.online}
                            parentHovered={hovered || props.parentHovered}
                            parentUnderInspection={
                              underInspection || props.parentUnderInspection
                            }
                          />
                          <group
                            position={[-14, 0, 52.5]}
                            rotation={[Math.PI / 2, -Math.PI / 2, -Math.PI]}>
                            <UmbilicalMale
                              online={props.online}
                              parentHovered={hovered || props.parentHovered}
                              parentUnderInspection={
                                underInspection || props.parentUnderInspection
                              }
                            />
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
              </group>
            </group>
          </Suspense>
        </ErrorBoundary>
      </group>
    </ErrorBoundary>
  );
}
