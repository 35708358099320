import { CloudProduceAddress } from 'model/src/common/CloudProduceAddress';
import {
  combine,
  create,
  fullString,
  join,
  towerId,
} from 'model/src/common/CloudProduceAddressUtility';
import {
  Axis,
  MicrocontrollerType,
  SumpSubsystem,
  TierSubsystem,
  TowerSubsystem,
} from 'model/src/common/Systems';
import { SeriesType } from 'model/src/series/SeriesTypes';
import { ValveStateReading } from 'model/src/series/ValveStateReading';
import { ValveState } from 'model/src/status/ValveState';
import React from 'react';

import OnlineStatus from '../../../../utopia/online-status/OnlineStatus';
import TierData from '../../../../utopia/tier-data/TierData';

const TierInspectionPanel = props => {
  const towerAddress = towerId(props.address);
  function isTower1(towerAddress: CloudProduceAddress) {
    return towerAddress.segments[2] === '1';
  }
  function isBottomTier(tierAddress: CloudProduceAddress) {
    return tierAddress.segments[4] === '1';
  }
  const getTierBelowAddress = (tierAddress: CloudProduceAddress) => {
    if (isBottomTier(tierAddress)) {
      return combine(
        towerAddress,
        create([TowerSubsystem.SUMP, SumpSubsystem.PUMP])
      );
    } else {
      const newTierAddress = new CloudProduceAddress([...tierAddress.segments]);
      newTierAddress.segments[4] = (
        Number(newTierAddress.segments[4]) - 1
      ).toString();
      return newTierAddress;
    }
  };

  const valveIsClosed = (tierAddress: CloudProduceAddress) => {
    const valveStateReading: ValveStateReading = props.dataMap.all.get(
      fullString(combine(tierAddress, create([SeriesType.VALVE_STATE])))
    ).data[0].reading;
    return (
      (valveStateReading.value
        ? valveStateReading.value
        : valveStateReading.valveState) === ValveState.CLOSED // TODO(philipp), ISSUE(1): Remove refs to valveState after migration
    );
  };

  const tierEgressFlowAddress: CloudProduceAddress = isBottomTier(props.address)
    ? combine(
        getTierBelowAddress(props.address),
        create([SeriesType.EXTERIOR_FLOW_LM])
      )
    : combine(
        getTierBelowAddress(props.address),
        create([SeriesType.INGRESS_FLOW_LM])
      );

  const tierEgressTotalLitersAddress: CloudProduceAddress = isBottomTier(
    props.address
  )
    ? combine(
        getTierBelowAddress(props.address),
        create([SeriesType.EXTERIOR_TOTAL_LITERS_SINCE_RESET])
      )
    : combine(
        getTierBelowAddress(props.address),
        create([SeriesType.INGRESS_TOTAL_LITERS_SINCE_RESET])
      );

  const parentIsOnlineData = props.dataMap.all.get(
    fullString(join(towerAddress, SeriesType.ONLINE))
  ).data;

  const parentIsOnline = parentIsOnlineData[0].reading.value;
  const parentTimeSince = parentIsOnlineData[0].timestamp;

  const isOnlineData = props.dataMap.all.get(
    fullString(join(props.address, SeriesType.ONLINE))
  ).data;

  const isOnline = isOnlineData[0].reading.value;
  const timeSince = isOnlineData[0].timestamp;

  const mostRecentTimeSince = Math.max(timeSince, parentTimeSince);

  const firmwareVersion = props.dataMap.all.get(
    fullString(
      combine(
        props.address,
        create([
          SeriesType.VERSION,
          MicrocontrollerType.RASPBERRY_PI,
          SeriesType.FIRMWARE_VERSION,
        ])
      )
    )
  )
    ? props.dataMap.all.get(
        fullString(
          combine(
            props.address,
            create([
              SeriesType.VERSION,
              MicrocontrollerType.RASPBERRY_PI,
              SeriesType.FIRMWARE_VERSION,
            ])
          )
        )
      ).data[0].reading.value
    : props.dataMap.all.get(
        fullString(
          combine(
            props.address,
            create([SeriesType.VERSION, SeriesType.FIRMWARE_VERSION])
          )
        )
      ).data[0].reading.value;

  const firmwareVersionUpdate = props.dataMap.all.get(
    fullString(
      combine(
        props.address,
        create([
          SeriesType.VERSION,
          MicrocontrollerType.RASPBERRY_PI,
          SeriesType.FIRMWARE_VERSION_UPDATE,
        ])
      )
    )
  )
    ? props.dataMap.all.get(
        fullString(
          combine(
            props.address,
            create([
              SeriesType.VERSION,
              MicrocontrollerType.RASPBERRY_PI,
              SeriesType.FIRMWARE_VERSION_UPDATE,
            ])
          )
        )
      ).data[0].reading.value
    : props.dataMap.all.get(
        fullString(
          combine(
            props.address,
            create([SeriesType.VERSION, SeriesType.FIRMWARE_VERSION_UPDATE])
          )
        )
      ).data[0].reading.value;

  return (
    <>
      <OnlineStatus
        firmwareVersion={firmwareVersion}
        firmwareVersionUpdate={firmwareVersionUpdate}
        online={parentIsOnline && isOnline}
        ipAddress={
          props.dataMap.all.get(
            fullString(join(props.address, SeriesType.IP_ADDRESS))
          ).data[0].reading.value
        }
        timeSince={mostRecentTimeSince}
        url={props.url}
      />
      <TierData
        acState={props.dataMap.all.get(
          fullString(combine(props.address, create([SeriesType.AC_STATE, '2'])))
        )}
        airTemperature={props.dataMap.all.get(
          fullString(
            combine(props.address, create([SeriesType.AIR_TEMPERATURE]))
          )
        )}
        doorPosition={props.dataMap.all.get(
          fullString(
            combine(
              props.address,
              create([TierSubsystem.DOOR, SeriesType.POSITION])
            )
          )
        )}
        egressFlow={props.dataMap.all.get(fullString(tierEgressFlowAddress))}
        egressTotalLiters={props.dataMap.all.get(
          fullString(tierEgressTotalLitersAddress)
        )}
        overflowFlow={props.dataMap.all.get(
          fullString(
            combine(
              props.address,
              create([TierSubsystem.PUMP, SeriesType.WATER_FLOW]) //TODO(austin): change URL
            )
          )
        )}
        fan1Pwm={props.dataMap.all.get(
          fullString(
            combine(
              props.address,
              create([TierSubsystem.FAN, '1', SeriesType.PWM])
            )
          )
        )}
        fan1Rpm={props.dataMap.all.get(
          fullString(
            combine(
              props.address,
              create([TierSubsystem.FAN, '1', SeriesType.RPM])
            )
          )
        )}
        fan1State={props.dataMap.all.get(
          fullString(
            combine(
              props.address,
              create([TierSubsystem.FAN, '1', SeriesType.FAN_STATE])
            )
          )
        )}
        fan2Pwm={props.dataMap.all.get(
          fullString(
            combine(
              props.address,
              create([TierSubsystem.FAN, '2', SeriesType.PWM])
            )
          )
        )}
        fan2Rpm={props.dataMap.all.get(
          fullString(
            combine(
              props.address,
              create([TierSubsystem.FAN, '2', SeriesType.RPM])
            )
          )
        )}
        fan2State={props.dataMap.all.get(
          fullString(
            combine(
              props.address,
              create([TierSubsystem.FAN, '2', SeriesType.FAN_STATE])
            )
          )
        )}
        gantryPositionX={props.dataMap.all.get(
          fullString(
            combine(
              props.address,
              create([TierSubsystem.GANTRY, Axis.X, SeriesType.POSITION])
            )
          )
        )}
        gantryPositionY={props.dataMap.all.get(
          fullString(
            combine(
              props.address,
              create([TierSubsystem.GANTRY, Axis.Y, SeriesType.POSITION])
            )
          )
        )}
        gantryPositionZ={props.dataMap.all.get(
          fullString(
            combine(
              props.address,
              create([TierSubsystem.GANTRY, Axis.Z, SeriesType.POSITION])
            )
          )
        )}
        heaterValveState={props.dataMap.all.get(
          fullString(
            combine(
              props.address,
              create([TierSubsystem.HEATER_VALVE, SeriesType.VALVE_STATE])
            )
          )
        )}
        ingressFlow={props.dataMap.all.get(
          fullString(
            combine(props.address, create([SeriesType.INGRESS_FLOW_LM]))
          )
        )}
        ingressTotalLiters={props.dataMap.all.get(
          fullString(
            combine(
              props.address,
              create([SeriesType.INGRESS_TOTAL_LITERS_SINCE_RESET])
            )
          )
        )}
        isDoorClosed={props.dataMap.all.get(
          fullString(
            combine(
              props.address,
              create([TierSubsystem.DOOR, SeriesType.MINIMUM_REACHED])
            )
          )
        )}
        isDoorOpen={props.dataMap.all.get(
          fullString(
            combine(
              props.address,
              create([TierSubsystem.DOOR, SeriesType.MAXIMUM_REACHED])
            )
          )
        )}
        lights={props.dataMap.all.get(
          fullString(combine(props.address, create([SeriesType.AC_STATE, '1'])))
        )}
        pumpPwm={props.dataMap.all.get(
          fullString(
            combine(props.address, create([TierSubsystem.PUMP, SeriesType.PWM]))
          )
        )}
        pumpStatus={props.dataMap.all.get(
          fullString(
            combine(
              props.address,
              create([TierSubsystem.PUMP, SeriesType.PUMP_STATUS])
            )
          )
        )}
        relativeHumidity={props.dataMap.all.get(
          fullString(
            combine(props.address, create([SeriesType.RELATIVE_HUMIDITY]))
          )
        )}
        valveState={props.dataMap.all.get(
          fullString(combine(props.address, create([SeriesType.VALVE_STATE])))
        )}
        waterPresent1={props.dataMap.all.get(
          fullString(
            combine(props.address, create([SeriesType.WATER_PRESENT, '1']))
          )
        )}
        waterPresent2={props.dataMap.all.get(
          fullString(
            combine(props.address, create([SeriesType.WATER_PRESENT, '2']))
          )
        )}
        waterTemperature1={props.dataMap.all.get(
          fullString(
            combine(props.address, create([SeriesType.WATER_TEMPERATURE, '1']))
          )
        )}
        waterTemperature2={props.dataMap.all.get(
          fullString(
            combine(props.address, create([SeriesType.WATER_TEMPERATURE, '2']))
          )
        )}
      />
    </>
  );
};

export default TierInspectionPanel;
