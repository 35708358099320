import _ from 'lodash';
import { CloudProduceAddress } from 'model/src/common/CloudProduceAddress';
import {
  combine,
  create,
  fullString,
  join,
  toAddress,
} from 'model/src/common/CloudProduceAddressUtility';
import { Operations, RegexPhrases } from 'model/src/common/Regex';
import { TowerSubsystem } from 'model/src/common/Systems';
import {
  PageToPageServer,
  SendCommand,
} from 'model/src/dataflowprotocol/pagetopageserver/PageToPageServer';
import { PageToPageServerType } from 'model/src/dataflowprotocol/pagetopageserver/PageToPageServerType';
import React, { useContext } from 'react';
import { getLoggerMapFromDataMap } from 'services/src/logging/LoggerMapUtility';

import { ConfirmationBoxWindowProps } from '../../farm-ui/windows/ConfirmationBoxWindow';
import {
  ControllerContext,
  ControllerContextType,
} from '../../three/farmviewer/context/controllercontext/ControllerContext';
import {
  DesktopContext,
  DesktopContextType,
} from '../../three/farmviewer/context/desktopcontext/DesktopContext';
import {
  OperationContext,
  OperationContextType,
} from '../../three/farmviewer/context/operationcontext/OperationContext';
import WindowType from '../window/WindowTypes';
import ContextButton from './button/ContextButton';
import RaspberryPiFirmwareContextButton from './button/RaspberryPiFirmwareContextButton';
import Divider from './Divider';

export default function TowerContextMenu(props) {
  const controllerContext: ControllerContextType = useContext(
    ControllerContext
  );
  const desktopContext: DesktopContextType = useContext(DesktopContext);
  const operationContext: OperationContextType = useContext(OperationContext);
  const placeTower = RegexPhrases.OPERATION + Operations.PLACEMENT;

  const baseTowerUrl: CloudProduceAddress = toAddress(props.url);

  const baseTowerLoggersUrl: CloudProduceAddress = combine(
    baseTowerUrl,
    create(['LOGGERS'])
  );

  const loggerMap = getLoggerMapFromDataMap(baseTowerLoggersUrl, props.dataMap);

  const titleCase = (string: string) => {
    return _.startCase(_.camelCase(string));
  };

  const sendCommandToAllTiers = (
    createCommand: (baseTowerUrl, tierUrl) => SendCommand
  ) => {
    for (let i = 1; i <= 7; i++) {
      const tierUrl = combine(
        toAddress(props.url),
        create([TowerSubsystem.TIER, i.toString()])
      );
      const newCommand: PageToPageServer = {
        type: PageToPageServerType.SendCommand,
        sendCommand: createCommand(baseTowerUrl, tierUrl),
      };
      props.connectionToServer.sendMessage(newCommand);
    }
    props.hideContextMenu();
  };

  const onClickWithConfirmation = (windowProps: ConfirmationBoxWindowProps) => {
    props.hideContextMenu();
    if (desktopContext.setModal)
      desktopContext.setModal(
        WindowType.CONFIRMATION,
        windowProps,
        desktopContext
      );
  };

  return (
    <>
      <RaspberryPiFirmwareContextButton
        baseUrl={baseTowerUrl}
        targetUrl={baseTowerUrl}
        connectionToServer={props.connectionToServer}
        dataMap={props.dataMap}
        hideContextMenu={props.hideContextMenu}
      />
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(baseTowerUrl),
                  rebootCommand: {
                    targetId: fullString(baseTowerUrl),
                    dummyData: true,
                  },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
            },
            queryText: props.url + ':\n Reboot Tower?',
          })
        }>
        Reboot
      </ContextButton>
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(baseTowerUrl),
                  shutdownCommand: {
                    targetId: fullString(baseTowerUrl),
                    dummyData: true,
                  },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
            },
            queryText: props.url + ':\n Shut Down Tower?',
          })
        }>
        Shut Down
      </ContextButton>
      <Divider />
      {Array.from(loggerMap.entries()).map(([loggerName, data]) => {
        return (
          //TODO(austin): add onClick
          <ContextButton
            key={loggerName}
            onClick={() => {
              onClickWithConfirmation({
                yesButtonEventHandler: () => {
                  const newDataUpdate: PageToPageServer = {
                    type: PageToPageServerType.SendDataUpdate,
                    sendDataUpdate: {
                      id: fullString(data.address),
                      datum: { value: !data.isEnabled },
                    },
                  };
                  props.connectionToServer.sendMessage(newDataUpdate);
                },
                queryText:
                  props.url +
                  ':\n ' +
                  (data.isEnabled ? 'Disable ' : 'Enable ') +
                  titleCase(loggerName) +
                  '?',
              });
            }}>
            {data.isEnabled ? 'Disable ' : 'Enable '}
            {titleCase(loggerName)}
          </ContextButton>
        );
      })}
      <Divider />
      <ContextButton
        onClick={() => {
          props.inspect();
        }}>
        Inspect
      </ContextButton>
      <ContextButton
        onClick={() => {
          props.select(props.url, controllerContext, desktopContext);
          operationContext.setPlacementOperation(
            {
              targetUrl: toAddress(props.url),
              placementLocationUrl: undefined,
            },
            operationContext
          );
          props.select(
            props.url,
            controllerContext,
            desktopContext,
            placeTower
          );
        }}>
        Place Tower
      </ContextButton>
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              sendCommandToAllTiers((baseTowerUrl, tierUrl) => {
                return {
                  targetId: fullString(baseTowerUrl),
                  doorOpenCommand: {
                    targetId: fullString(tierUrl),
                    dummyData: true,
                  },
                };
              });
            },
            queryText: props.url + ':\n Open all doors?',
          })
        }>
        Open All Doors
      </ContextButton>
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              sendCommandToAllTiers((baseTowerUrl, tierUrl) => {
                return {
                  targetId: fullString(baseTowerUrl),
                  doorCloseCommand: {
                    targetId: fullString(tierUrl),
                    dummyData: true,
                  },
                };
              });
            },
            queryText: props.url + ':\n Close all doors?',
          })
        }>
        Close All Doors
      </ContextButton>
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              sendCommandToAllTiers((baseTowerUrl, tierUrl) => {
                return {
                  targetId: fullString(baseTowerUrl),
                  lightsOnCommand: {
                    targetId: fullString(tierUrl),
                    dummyData: true,
                  },
                };
              });
            },
            queryText: props.url + ':\n Turn on all lights?',
          })
        }>
        All Lights On
      </ContextButton>
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              sendCommandToAllTiers((baseTowerUrl, tierUrl) => {
                return {
                  targetId: fullString(baseTowerUrl),
                  lightsOffCommand: {
                    targetId: fullString(tierUrl),
                    dummyData: true,
                  },
                };
              });
            },
            queryText: props.url + ':\n Turn off all lights?',
          })
        }>
        All Lights Off
      </ContextButton>
      <ContextButton
        onClick={() => {
          props.select(props.url, controllerContext, desktopContext);
        }}>
        Select
      </ContextButton>
    </>
  );
}
