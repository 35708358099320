import { CloudProduceAddress } from 'model/src/common/CloudProduceAddress';
import {
  cutTo,
  fullString,
  toAddress,
} from 'model/src/common/CloudProduceAddressUtility';
import { PageToPageServer } from 'model/src/dataflowprotocol/pagetopageserver/PageToPageServer';
import { PageToPageServerType } from 'model/src/dataflowprotocol/pagetopageserver/PageToPageServerType';
import React, { useContext } from 'react';

import { ConfirmationBoxWindowProps } from '../../farm-ui/windows/ConfirmationBoxWindow';
import {
  ControllerContext,
  ControllerContextType,
} from '../../three/farmviewer/context/controllercontext/ControllerContext';
import {
  DesktopContext,
  DesktopContextType,
} from '../../three/farmviewer/context/desktopcontext/DesktopContext';
import WindowType from '../window/WindowTypes';
import ContextButton from './button/ContextButton';
import SubMenuButton from './button/SubMenuButton';

enum UmbilicalSubCategories {
  GANTRY = 'GANTRY',
}

export default function UmbilicalContextMenu(props) {
  const controllerContext: ControllerContextType = useContext(
    ControllerContext
  );
  const desktopContext: DesktopContextType = useContext(DesktopContext);

  const onClickWithConfirmation = (windowProps: ConfirmationBoxWindowProps) => {
    props.hideContextMenu();
    if (desktopContext.setModal) {
      desktopContext.setModal(
        WindowType.CONFIRMATION,
        windowProps,
        desktopContext
      );
    }
  };
  return (
    <>
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const towerUrl = cutTo(toAddress(props.url), -1);
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(towerUrl),
                  acOnCommand: { dummyData: true },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
              props.hideContextMenu();
            },
            queryText: props.url + ':\n Turn AC on?',
          })
        }>
        AC On
      </ContextButton>
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const towerUrl = cutTo(toAddress(props.url), -1);
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(towerUrl),
                  acOffCommand: { dummyData: true },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
              props.hideContextMenu();
            },
            queryText: props.url + ':\n Turn AC off?',
          })
        }>
        AC Off
      </ContextButton>
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const towerUrl = cutTo(toAddress(props.url), -1);
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(towerUrl),
                  valveOpenCommand: { dummyData: true },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
              props.hideContextMenu();
            },
            queryText: props.url + ':\n Open umbilical valve?',
          })
        }>
        Valve Open
      </ContextButton>
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const towerUrl = cutTo(toAddress(props.url), -1);
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(towerUrl),
                  valveCloseCommand: { dummyData: true },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
              props.hideContextMenu();
            },
            queryText: props.url + ':\n Close Umbilical Valve?',
          })
        }>
        Valve Close
      </ContextButton>
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const towerUrl = cutTo(toAddress(props.url), -1);
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(towerUrl),
                  pumpOnCommand: { dummyData: true },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
              props.hideContextMenu();
            },
            queryText: props.url + ':\n Turn unbilical pump on?',
          })
        }>
        Pump On
      </ContextButton>
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const towerUrl = cutTo(toAddress(props.url), -1);
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(towerUrl),
                  pumpOffCommand: { dummyData: true },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
              props.hideContextMenu();
            },
            queryText: props.url + ':\n Turn umbilical pump off?',
          })
        }>
        Pump Off
      </ContextButton>
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const towerUrl = cutTo(toAddress(props.url), -1);
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(towerUrl),
                  pumpAutoCommand: { dummyData: true },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
              props.hideContextMenu();
            },
            queryText: props.url + ':\n Set umbilical pump to auto?',
          })
        }>
        Pump Auto
      </ContextButton>
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const towerUrl = cutTo(toAddress(props.url), -1);
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(towerUrl),
                  pumpToEmptyCommand: { dummyData: true },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
              props.hideContextMenu();
            },
            queryText: props.url + ':\n Pump to empty?',
          })
        }>
        Pump To Empty
      </ContextButton>
      <ContextButton
        onClick={() => {
          props.inspect();
        }}>
        Inspect
      </ContextButton>
      <ContextButton
        onClick={() => {
          props.select(props.url, controllerContext, desktopContext);
        }}>
        Select
      </ContextButton>
      <ContextButton
        onClick={() => {
          const address: CloudProduceAddress = cutTo(toAddress(props.url), -1);
          const newCommand: PageToPageServer = {
            type: PageToPageServerType.SendCommand,
            sendCommand: {
              targetId: fullString(address),
              initializeCommand: { dummyData: true },
            },
          };
          props.connectionToServer.sendMessage(newCommand);
          props.hideContextMenu();
        }}>
        Initialize
      </ContextButton>
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const address: CloudProduceAddress = cutTo(
                toAddress(props.url),
                -1
              );
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(address),
                  dockCommand: { dummyData: true },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
              props.hideContextMenu();
            },
            queryText: props.url + ':\n Dock umbilical?',
          })
        }>
        Dock
      </ContextButton>
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const address: CloudProduceAddress = cutTo(
                toAddress(props.url),
                -1
              );
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(address),
                  undockCommand: { dummyData: true },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
              props.hideContextMenu();
            },
            queryText: props.url + ':\n Undock umbilical?',
          })
        }>
        Undock
      </ContextButton>
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const towerUrl = cutTo(toAddress(props.url), -1);
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(towerUrl),
                  umbilicalGantryHomeCommand: {
                    targetId: fullString(towerUrl),
                    dummyData: true,
                  },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
              props.hideContextMenu();
            },
            queryText: props.url + ':\n Home Gantry?',
          })
        }>
        Home
      </ContextButton>
      <SubMenuButton
        id={UmbilicalSubCategories.GANTRY}
        openSubMenu={props.openSubMenu}
        buttonText={'Gantry'}
        setOpenSubMenu={props.setOpenSubMenu}>
        <ContextButton
          onClick={() =>
            onClickWithConfirmation({
              yesButtonEventHandler: () => {
                const towerUrl = cutTo(toAddress(props.url), -1);
                const newCommand: PageToPageServer = {
                  type: PageToPageServerType.SendCommand,
                  sendCommand: {
                    targetId: fullString(towerUrl),
                    gantryHardStopCommand: {
                      targetId: fullString(towerUrl),
                      dummyData: true,
                    },
                  },
                };
                props.connectionToServer.sendMessage(newCommand);
              },
              queryText: props.url + ':\n Gantry: Hard stop?',
            })
          }
          setOpenSubMenu={props.setOpenSubMenu}>
          Gantry Hard Stop
        </ContextButton>
        <ContextButton
          onClick={() =>
            onClickWithConfirmation({
              yesButtonEventHandler: () => {
                const towerUrl = cutTo(toAddress(props.url), -1);
                const newCommand: PageToPageServer = {
                  type: PageToPageServerType.SendCommand,
                  sendCommand: {
                    targetId: fullString(towerUrl),
                    gantryStopCommand: {
                      targetId: fullString(towerUrl),
                      dummyData: true,
                    },
                  },
                };
                props.connectionToServer.sendMessage(newCommand);
              },
              queryText: props.url + ':\n Gantry: Stop?',
            })
          }
          setOpenSubMenu={props.setOpenSubMenu}>
          Gantry Stop
        </ContextButton>
        <ContextButton
          onClick={() =>
            onClickWithConfirmation({
              yesButtonEventHandler: () => {
                const towerUrl = cutTo(toAddress(props.url), -1);
                const newCommand: PageToPageServer = {
                  type: PageToPageServerType.SendCommand,
                  sendCommand: {
                    targetId: fullString(towerUrl),
                    gantryHomeCommand: {
                      targetId: fullString(towerUrl),
                      dummyData: true,
                    },
                  },
                };
                props.connectionToServer.sendMessage(newCommand);
              },
              queryText: props.url + ':\n Gantry: Go home?',
            })
          }
          setOpenSubMenu={props.setOpenSubMenu}>
          Gantry Home
        </ContextButton>
      </SubMenuButton>
    </>
  );
}
