import { CloudProduceAddress } from 'model/src/common/CloudProduceAddress';
import {
  fullString,
  isTug,
  toAddress,
} from 'model/src/common/CloudProduceAddressUtility';
import { urlCombine } from 'model/src/common/Systems';
import { Data } from 'model/src/dataflowprotocol/Datum';
import { Path, PathNode } from 'model/src/navigation/Path';
import { PathReading } from 'model/src/series/PathReading';
import { SeriesType } from 'model/src/series/SeriesTypes';
import React, { useMemo, useState } from 'react';
import { uuid } from 'uuidv4';

import { InspectionNode } from '../../InspectionNodeUtility';
import { EdgeAxis, EdgeDirection, PathContext, PathEdge } from './PathContext';

export default function PathContextProvider(props) {
  const inspectionContext = props.inspectionContext;
  let tugUrl: string | undefined = undefined;
  inspectionContext.flattenedInspectionMap.forEach(
    (inspectionNode: InspectionNode) => {
      const address: CloudProduceAddress = toAddress(inspectionNode.url);
      if (!tugUrl && isTug(address)) {
        tugUrl = inspectionNode.url;
      }
    }
  );

  const pathUrl = useMemo(() => {
    return tugUrl !== undefined
      ? tugUrl + urlCombine([SeriesType.PATH])
      : undefined;
  }, [tugUrl]);

  const path: Path | undefined = useMemo(() => {
    if (pathUrl) {
      const pathDatum: Data<PathReading> = props.dataMap.all.get(pathUrl);
      return pathDatum.data[0].reading.path;
    } else {
      return undefined;
    }
  }, [pathUrl, props.dataMap]);
  // let nodeId = 0;
  // const path: Path | undefined = useMemo(() => {
  //   return pathUrl
  //     ? {
  //         id: toAddress(pathUrl),
  //         status: PathStatus.READY,
  //         nodes: [
  //           {
  //             id: nodeId++ + '',
  //             floorNodeId: create(['1', FarmSubsystem.FLOOR, '7', '3']),
  //             onArrive: [],
  //             reached: false,
  //           },
  //           {
  //             id: nodeId++ + '',
  //             floorNodeId: create(['1', FarmSubsystem.FLOOR, '7', '2']),
  //             onArrive: [],
  //             reached: false,
  //           },
  //           {
  //             id: nodeId++ + '',
  //             floorNodeId: create(['1', FarmSubsystem.FLOOR, '7', '1']),
  //             onArrive: [],
  //             reached: false,
  //           },
  //           {
  //             id: nodeId++ + '',
  //             floorNodeId: create(['1', FarmSubsystem.FLOOR, '7', '2']),
  //             onArrive: [],
  //             reached: false,
  //           },
  //           {
  //             id: nodeId++ + '',
  //             floorNodeId: create(['1', FarmSubsystem.FLOOR, '7', '3']),
  //             onArrive: [],
  //             reached: false,
  //           },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '7', '3']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '7', '2']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '7', '1']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '6', '1']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '5', '1']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '5', '2']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '5', '3']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '6', '3']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '7', '3']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '7', '2']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '7', '1']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '6', '1']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '5', '1']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '5', '2']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '5', '3']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '6', '3']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '7', '3']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '7', '4']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '6', '4']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '5', '4']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '4', '4']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '3', '4']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },

  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '2', '4']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },

  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '2', '3']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },

  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '2', '2']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },

  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '2', '1']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },

  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '3', '1']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },

  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '4', '1']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },

  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '4', '2']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },

  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '4', '3']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },

  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '3', '3']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },

  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '2', '3']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },

  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '2', '2']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '2', '1']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },

  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '3', '1']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },

  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '4', '1']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },

  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '4', '2']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },

  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '4', '3']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },

  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '3', '3']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },

  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '2', '3']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },

  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '1', '3']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },

  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '9', '0']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '8', '0']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '8', '1']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '7', '1']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '6', '1']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '6', '0']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '7', '0']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '7', '1']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '6', '1']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '6', '0']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '5', '0']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '5', '1']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '5', '2']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '4', '2']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '4', '3']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '5', '3']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '6', '3']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '7', '3']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '7', '2']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '6', '2']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '6', '3']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '7', '3']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '7', '2']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '6', '2']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '6', '3']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //           // {
  //           //   id: nodeId++ + '',
  //           //   floorNodeId: create(['1', FarmSubsystem.FLOOR, '7', '3']),
  //           //   onArrive: [],
  //           //   reached: false,
  //           // },
  //         ],
  //       }
  //     : undefined;
  // }, [pathUrl]);

  const { edgeMap, edges } = useMemo(() => {
    const edges: PathEdge[] = [];
    const edgeMap = new Map<string, PathEdge[]>();
    if (path) {
      const nodeChannels = new Map<string, number>();

      for (let i = 1; i < path.nodes.length; i++) {
        const sourceNode: PathNode = path.nodes[i - 1];
        const sourceFloorNodeId = fullString(sourceNode.floorNodeId);

        const destinationNode: PathNode = path.nodes[i];
        const destinationFloorNodeId = fullString(destinationNode.floorNodeId);

        const sourceX: number = parseInt(sourceNode.floorNodeId.segments[3]);
        const sourceY: number = parseInt(sourceNode.floorNodeId.segments[2]);
        const destinationX: number = parseInt(
          destinationNode.floorNodeId.segments[3]
        );
        const destinationY: number = parseInt(
          destinationNode.floorNodeId.segments[2]
        );
        let edgeDirection: EdgeDirection;
        let edgeAxis: EdgeAxis;

        if (sourceX === destinationX) {
          if (sourceY < destinationY) {
            edgeDirection = EdgeDirection.BACK;
          } else {
            edgeDirection = EdgeDirection.FORWARD;
          }
          edgeAxis = EdgeAxis.VERTICAL;
        } else {
          if (sourceX < destinationX) {
            edgeDirection = EdgeDirection.LEFT;
          } else {
            edgeDirection = EdgeDirection.RIGHT;
          }
          edgeAxis = EdgeAxis.HORIZONTAL;
        }

        const sourceDistance = i - 1;

        if (!nodeChannels.has(sourceFloorNodeId + '_' + edgeAxis + '_source')) {
          nodeChannels.set(sourceFloorNodeId + '_' + edgeAxis + '_source', 0);
        }
        const sourceChannel: number =
          nodeChannels.get(sourceFloorNodeId + '_' + edgeAxis + '_source') || 0;
        nodeChannels.set(
          sourceFloorNodeId + '_' + edgeAxis + '_source',
          sourceChannel + 1
        );

        const destinationDistance = i;
        if (
          !nodeChannels.has(
            destinationFloorNodeId + '_' + edgeAxis + '_destination'
          )
        ) {
          nodeChannels.set(
            destinationFloorNodeId + '_' + edgeAxis + '_destination',
            0
          );
        }
        const destinationChannel: number =
          nodeChannels.get(
            destinationFloorNodeId + '_' + edgeAxis + '_destination'
          ) || 0;
        nodeChannels.set(
          destinationFloorNodeId + '_' + edgeAxis + '_destination',
          destinationChannel + 1
        );

        const edge: PathEdge = {
          id: uuid(),
          sourceNodeId: sourceNode.id,
          sourceFloorNodeId: sourceNode.floorNodeId,
          sourceDistance,
          sourceChannel,
          destinationNodeId: destinationNode.id,
          destinationFloorNodeId: destinationNode.floorNodeId,
          destinationDistance,
          destinationChannel,
          edgeAxis,
          edgeDirection,
          edgeDistance: edges.length,
        };
        edges.push(edge);

        if (!edgeMap.has(sourceFloorNodeId)) {
          edgeMap.set(sourceFloorNodeId, []);
        }
        if (!edgeMap.has(destinationFloorNodeId)) {
          edgeMap.set(destinationFloorNodeId, []);
        }
        edgeMap.get(sourceFloorNodeId)!.push(edge);
        edgeMap.get(destinationFloorNodeId)!.push(edge);
      }
    }
    return { edgeMap, edges };
  }, [path]);

  const getEdgesByFloorNode = (floorNodeId: string): PathEdge[] => {
    return edgeMap.get(floorNodeId) || [];
  };

  const getEdgeByDistance = (distance: number): PathEdge => {
    return edges[distance];
  };

  const getPathSegmentChannel = (distance: number): number => {
    const initialEdge = getEdgeByDistance(distance);
    let maxChannel = Math.max(
      initialEdge.destinationChannel,
      initialEdge.sourceChannel
    );
    const edgeDirection: EdgeDirection = initialEdge.edgeDirection;
    let currentEdgeDirection: EdgeDirection = edgeDirection;
    let currentDistance = distance;
    while (edgeDirection === currentEdgeDirection && currentDistance > 0) {
      const edge = getEdgeByDistance(currentDistance);
      currentEdgeDirection = edge.edgeDirection;
      if (edgeDirection === currentEdgeDirection) {
        const localmaxChannel = Math.max(
          edge.sourceChannel,
          edge.destinationChannel
        );
        if (localmaxChannel > maxChannel) {
          maxChannel = localmaxChannel;
        }
      }
      currentDistance--;
    }
    currentEdgeDirection = edgeDirection;
    currentDistance = distance;
    while (
      edgeDirection === currentEdgeDirection &&
      currentDistance < edges.length - 1
    ) {
      const edge = getEdgeByDistance(currentDistance);
      currentEdgeDirection = edge.edgeDirection;
      if (edgeDirection === currentEdgeDirection) {
        const localmaxChannel = Math.max(
          edge.sourceChannel,
          edge.destinationChannel
        );
        if (localmaxChannel > maxChannel) {
          maxChannel = localmaxChannel;
        }
      }
      currentDistance++;
    }

    return maxChannel;
  };

  // const [graph, setGraph] = useState<Map<string, FloorNode>>(
  //   new Map<string, FloorNode>()
  // );

  // const [pathSource, setPathSource] = useState<string>();
  // const [pathDestination, setPathDestination] = useState<string>();

  // const [newPath, setNewPath] = useState<Path>({
  //   id: create(['1', 'PATH', '1']),
  //   nodes: [],
  //   status: PathStatus.READY,
  // });
  const [renderPath, setRenderPath] = useState(false);

  // const createNewPath = () => {
  //   const path: Path = {
  //     id: create(['1', 'PATH', '1']),
  //     nodes: [],
  //     status: PathStatus.READY,
  //   };
  //   setNewPath(path);
  // };

  const doSetRenderPath = (doRenderPath: boolean) => {
    setRenderPath(doRenderPath);
  };

  // const addNodeToPath = (url: string) => {
  //   const newNode: PathNode = {
  //     id: uuid(),
  //     floorNodeId: toAddress(url),
  //     onArrive: [],
  //     reached: false,
  //   };
  //   newPath.nodes.push(newNode);
  //   const path: Path = { ...newPath };
  //   setNewPath(path);
  // };

  // const isNodeInPath = (url: string) => {
  //   let found = false;
  //   newPath.nodes.forEach(node => {
  //     const nodeId = fullString(node.floorNodeId);
  //     if (nodeId == url) {
  //       found = true;
  //     }
  //   });
  //   return found;
  // };

  const contextValue = {
    // setGraph,
    // setPathDestination,
    // setPathSource,
    // newPath,
    // createNewPath,
    tugUrl,
    getEdgesByFloorNode,
    getEdgeByDistance,
    getPathSegmentChannel,
    renderPath,
    setRenderPath: doSetRenderPath,
  };

  return (
    <PathContext.Provider value={contextValue}>
      {props.children}
    </PathContext.Provider>
  );
}
