import {
  cutTo,
  farmId,
  fullString,
  isTier,
  isTower,
  isTug,
  join,
  toAddress,
} from 'model/src/common/CloudProduceAddressUtility';
import {
  ArduinoType,
  MicrocontrollerType,
  TowerSubsystem,
  TugSubsystem,
} from 'model/src/common/Systems';
import { DriveCommandType } from 'model/src/dataflowprotocol/pagetopageserver/PageToPageServer';
import { PageToPageServerType } from 'model/src/dataflowprotocol/pagetopageserver/PageToPageServerType';
import { ServerToFarmType } from 'model/src/dataflowprotocol/servertofarm/ServerToFarmType';
import { NavigationalIntentType } from 'model/src/navigation/NavigationalIntent';
import TimerTemplate, {
  TimerTemplateUpdateType,
} from 'model/src/timer/TimerTemplate';
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';

import ClassNames from '../../ClassNames';
import InjectionContext, {
  InjectionContextType,
} from '../../injection/InjectionContext';
import {
  DesktopContext,
  DesktopContextType,
} from '../../three/farmviewer/context/desktopcontext/DesktopContext';
import Schedule24HourBackground from '../../utopia/scheduler-data/Schedule24HourBackground';
import SchedulerDropdownMenu from '../../utopia/scheduler-data/SchedulerDropdownMenu';
import SchedulerTimerTemplateModal from '../../utopia/scheduler-data/SchedulerTimerTemplateModal';
import { HeaderButtonType } from '../../utopia/window/components/WindowHeaderButton';
import DraggableWindow from '../../utopia/window/DraggableWindow';
import WindowType from '../../utopia/window/WindowTypes';

export type SchedulerWindowProps = {
  closeHandler;
  dataMap;
  initialWindowPosition;
  onClick?;
  onDrag;
  onDragEnd;
  onDragStart;
  onMouseDown;
  selected;
  showContextMenu;
  hideContextMenu;
};

export const getModalFields = (
  commandType: ServerToFarmType,
  targetId: string
) => {
  const commandTargetId = targetId;

  const messageTargetId = isTier(toAddress(commandTargetId))
    ? fullString(cutTo(toAddress(commandTargetId), 3))
    : commandTargetId;

  switch (commandType) {
    case ServerToFarmType.AcOffCommand:
      return {
        targetId: messageTargetId,
        acOffCommand: { targetId: commandTargetId, dummyData: true },
      };
    case ServerToFarmType.AcOnCommand:
      return {
        targetId: messageTargetId,
        acOnCommand: { targetId: commandTargetId, dummyData: true },
      };
    case ServerToFarmType.ChangeLightLevelCommand:
      return {
        targetId: messageTargetId,
        changeLightLevelCommand: { lightLevel: 0, transitionDurationMs: 0 },
      };
    case ServerToFarmType.DoorCloseCommand:
      return {
        targetId: messageTargetId,
        doorCloseCommand: { targetId: commandTargetId, dummyData: true },
      };
    case ServerToFarmType.DoorOpenCommand:
      return {
        targetId: messageTargetId,
        doorOpenCommand: { targetId: commandTargetId, dummyData: true },
      };
    case ServerToFarmType.DockCommand:
      return { targetId: messageTargetId, dockCommand: { dummyData: true } };
    case ServerToFarmType.UndockCommand:
      return { targetId: messageTargetId, undockCommand: { dummyData: true } };
    case ServerToFarmType.DrainCloseCommand:
      return {
        targetId: messageTargetId,
        drainCloseCommand: { targetId: messageTargetId, dummyData: true },
      };
    case ServerToFarmType.DrainOpenCommand:
      return {
        targetId: messageTargetId,
        drainOpenCommand: { targetId: messageTargetId, dummyData: true },
      };
    case ServerToFarmType.DriveCommand: //TODO(austin): implement
      return {
        //TODO(austin): ask for default values
        commandType: DriveCommandType.FORWARD,
        speed: 0,
        move: { byMm: 0 },
        turn: { byDeg: 0 },
      };
    case ServerToFarmType.DriveCommand2: //TODO(austin): implement
      return {
        //TODO(austin): ask for default values
        linearVelocity: 0,
        angle: 0,
        angularVelocity: 0,
        maxWheelSpeedMS: 0,
      };
    case ServerToFarmType.FansOffCommand:
      return {
        targetId: messageTargetId,
        fansOffCommand: { targetId: commandTargetId, dummyData: true },
      };
    case ServerToFarmType.FansOnCommand:
      return {
        targetId: messageTargetId,
        fansOnCommand: { targetId: commandTargetId, dummyData: true },
      };
    case ServerToFarmType.GantryFindFarXCommand:
      return {
        targetId: messageTargetId,
        gantryFindFarXCommand: { targetId: commandTargetId, dummyData: true },
      };
    case ServerToFarmType.GantryFindFarYCommand:
      return {
        targetId: messageTargetId,
        gantryFindFarYCommand: { targetId: commandTargetId, dummyData: true },
      };
    case ServerToFarmType.GantryFindFarZCommand:
      return {
        targetId: messageTargetId,
        gantryFindFarZCommand: { targetId: commandTargetId, dummyData: true },
      };
    case ServerToFarmType.GantryGoToCommand: //TODO(austin) implement
      return { x_mm: 0, y_mm: 0, z_mm: 0 }; //TODO(austin): ask for default values
    case ServerToFarmType.GantryHardStopCommand:
      return {
        targetId: messageTargetId,
        gantryHardStopCommand: { targetId: commandTargetId, dummyData: true },
      };
    case ServerToFarmType.GantryHomeCommand:
      return {
        targetId: messageTargetId,
        gantryHomeCommand: { targetId: commandTargetId, dummyData: true },
      };
    case ServerToFarmType.GantryMoveByCommand:
      return { x_mm: 0, y_mm: 0, z_mm: 0 };
    case ServerToFarmType.GantryStopCommand:
      return {
        targetId: messageTargetId,
        gantryStopCommand: { targetId: commandTargetId, dummyData: true },
      };
    case ServerToFarmType.InitializeCommand: //TODO(austin): implement
      return { dummyData: true };
    case ServerToFarmType.LiftCommand: //TODO(austin): implement
      return {
        //TODO(austin): ask for default values
        absolutePosition: 0,
        power: 0,
        weight: 0,
        speed: 0,
        shouldHome: false,
      };
    case ServerToFarmType.LightsOffCommand:
      return {
        targetId: messageTargetId,
        lightsOffCommand: { targetId: commandTargetId, dummyData: true },
      };
    case ServerToFarmType.LightsOnCommand:
      return {
        targetId: messageTargetId,
        lightsOnCommand: { targetId: commandTargetId, dummyData: true },
      };
    case ServerToFarmType.NavigateCommand: //TODO(austin): implement
      return {
        //TODO(austin): ask for default values
        navigationalIntent: {
          type: NavigationalIntentType.STOP,
          speedMps: 0,
          distance: 0,
        },
      };
    case ServerToFarmType.PumpOffCommand:
      return {
        targetId: messageTargetId,
        pumpOffCommand: { targetId: commandTargetId, dummyData: true },
      };
    case ServerToFarmType.PumpOnCommand:
      return {
        targetId: messageTargetId,
        pumpOnCommand: { targetId: commandTargetId, dummyData: true },
      };
    case ServerToFarmType.PumpAutoCommand:
      return {
        targetId: messageTargetId,
        pumpAutoCommand: { targetId: commandTargetId, dummyData: true },
      };
    case ServerToFarmType.PumpToEmptyCommand:
      return {
        targetId: messageTargetId,
        pumpToEmptyCommand: { targetId: commandTargetId, dummyData: true },
      };
    case ServerToFarmType.RebootCommand:
      return {
        targetId: messageTargetId,
        rebootCommand: { targetId: commandTargetId, dummyData: true },
      };
    case ServerToFarmType.ResetDeadReckoningCommand: //TODO(austin): implement
      return { segmentId: '' }; //TODO(austin): is this a url?
    case ServerToFarmType.ResetFlowInCommand:
      return {
        targetId: messageTargetId,
        resetFlowInCommand: { targetId: commandTargetId, dummyData: true },
      };
    case ServerToFarmType.ResetFlowOutCommand:
      return {
        targetId: messageTargetId,
        resetFlowOutCommand: { targetId: commandTargetId, dummyData: true },
      };
    case ServerToFarmType.ShutDownCommand:
      return {
        targetId: messageTargetId,
        shutDownCommand: { targetId: commandTargetId, dummyData: true },
      };
    case ServerToFarmType.UmbilicalGantryHomeCommand:
      return {
        targetId: messageTargetId,
        umbilicalGantryHomeCommand: {
          targetId: commandTargetId,
          dummyData: true,
        },
      };
    case ServerToFarmType.UpdateFirmwareCommand:
      return {
        targetId: messageTargetId,
        updateFirmwareCommand: {
          targetId: commandTargetId,
          microcontrollerType: MicrocontrollerType,
          arduinoType: ArduinoType,
        },
      };
    case ServerToFarmType.ValveCloseCommand:
      return {
        targetId: messageTargetId,
        valveCloseCommand: { targetId: commandTargetId, dummyData: true },
      };
    case ServerToFarmType.ValveOpenCommand:
      return {
        targetId: messageTargetId,
        valveOpenCommand: { targetId: commandTargetId, dummyData: true },
      };
    default:
      console.log(
        'SchedulerWindow::getModalFields: missing type: ' + commandType
      );
      return {};
  }
};

export const getActionLabel = (command: ServerToFarmType, logger) => {
  switch (command) {
    case ServerToFarmType.AcOffCommand:
      return 'Ac Off';
    case ServerToFarmType.AcOnCommand:
      return 'Ac On';
    case ServerToFarmType.ChangeLightLevelCommand:
      return 'Change Light Level';
    case ServerToFarmType.CloseConnectionRequest:
      return 'Close Connection Request';
    case ServerToFarmType.CommandChangeValveState:
      return 'Change Valve State';
    case ServerToFarmType.CommandLineCommand:
      return 'Command Line';
    case ServerToFarmType.DoorCloseCommand:
      return 'Door Close';
    case ServerToFarmType.DoorOpenCommand:
      return 'Door Open';
    case ServerToFarmType.DockCommand:
      return 'Dock';
    case ServerToFarmType.UndockCommand:
      return 'Undock';
    case ServerToFarmType.DrainCloseCommand:
      return 'Drain Close';
    case ServerToFarmType.DrainOpenCommand:
      return 'Drain Open';
    case ServerToFarmType.DriveCommand:
      return 'Drive';
    case ServerToFarmType.DriveCommand2:
      return 'Drive';
    case ServerToFarmType.FansOffCommand:
      return 'Fans Off';
    case ServerToFarmType.FansOnCommand:
      return 'Fans On';
    case ServerToFarmType.GantryFindFarXCommand:
      return 'Gantry: Find Far X';
    case ServerToFarmType.GantryFindFarYCommand:
      return 'Gantry: Find Far Y';
    case ServerToFarmType.GantryFindFarZCommand:
      return 'Gantry Find Far Z';
    case ServerToFarmType.GantryGoToCommand:
      return 'Gantry Go To';
    case ServerToFarmType.GantryHardStopCommand:
      return 'Gantry Hard Stop';
    case ServerToFarmType.GantryHomeCommand:
      return 'Gantry Home';
    case ServerToFarmType.GantryMoveByCommand:
      return 'Gantry Move By';
    case ServerToFarmType.GantryStopCommand:
      return 'Gantry Stop';
    case ServerToFarmType.InitializeCommand:
      return 'Initialize';
    case ServerToFarmType.LiftCommand:
      return 'Lift';
    case ServerToFarmType.LightsOffCommand:
      return 'Lights Off';
    case ServerToFarmType.LightsOnCommand:
      return 'Lights On';
    case ServerToFarmType.MessageRequest:
      return 'Message Request';
    case ServerToFarmType.NavigateCommand:
      return 'Navigate';
    case ServerToFarmType.Pong:
      return 'Pong';
    case ServerToFarmType.PumpOffCommand:
      return 'Pump Off';
    case ServerToFarmType.PumpOnCommand:
      return 'Pump On';
    case ServerToFarmType.PumpAutoCommand:
      return 'Pump Auto';
    case ServerToFarmType.PumpToEmptyCommand:
      return 'Pump To Empty';
    case ServerToFarmType.RebootCommand:
      return 'Reboot';
    case ServerToFarmType.ResetDeadReckoningCommand:
      return 'Reset Dead Reckoning';
    case ServerToFarmType.ResetFlowInCommand:
      return 'Reset Flow In';
    case ServerToFarmType.ResetFlowOutCommand:
      return 'Reset Flow In';
    case ServerToFarmType.ShutDownCommand:
      return 'Shut Down';
    case ServerToFarmType.UmbilicalGantryHomeCommand:
      return 'Umbilical Gantry Home';
    case ServerToFarmType.UpdateFirmwareCommand:
      return 'Update Firmware';
    case ServerToFarmType.ValveCloseCommand:
      return 'Valve Close';
    case ServerToFarmType.ValveOpenCommand:
      return 'Valve Open';
    case ServerToFarmType.WhoAreYouRequest:
      return '"WhoAreYou" Request';
    case ServerToFarmType.WhoAmIResponse:
      return '"WhoAmI" Response';
    default:
      logger.warn('SchedulerWindow::getActionLabel: missing type: ' + command);
  }
};

const Content = styled.div`
  width: var(--schedulerWindowWidth);
  border-radius: var(--windowBottomBorderRadius);

  color: var(--black);
  background-color: var(--dropdownMenuItemBackground_Default);
`;

const SchedulerWindow = (props: PropsWithChildren<SchedulerWindowProps>) => {
  const [currentTime, setCurrentTime] = useState(new Date());

  const [tierMenuIsOpen, setTierMenuIsOpen] = useState(false);
  const [towerMenuIsOpen, setTowerMenuIsOpen] = useState(false);
  const [stackMenuIsOpen, setStackMenuIsOpen] = useState(false);
  const [tugMenuIsOpen, setTugMenuIsOpen] = useState(false);

  const closeMenus = () => {
    setStackMenuIsOpen(false);
    setTierMenuIsOpen(false);
    setTowerMenuIsOpen(false);
    setTugMenuIsOpen(false);
  };

  const [selectedTier, setSelectedTier] = useState('');
  const [selectedTower, setSelectedTower] = useState('');
  const [selectedStack, setSelectedStack] = useState('');
  const [selectedTug, setSelectedTug] = useState('');
  const [
    selectedTimerTemplate,
    setSelectedTimerTemplate,
  ] = useState<TimerTemplate | null>(null);
  const [modal, setModal] = useState<{
    commandType: ServerToFarmType | null;
    updateType: TimerTemplateUpdateType | null;
    fields?: any;
  }>({ commandType: null, updateType: null });
  const [sendCommand, setSendCommand] = useState();

  const desktopContext: DesktopContextType = useContext(DesktopContext);
  const injectionContext: InjectionContextType = useContext(InjectionContext);
  const currentFarm = farmId(desktopContext.selectedUrl);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const getCurrentTimerTemplates = (
    targetUrl: string,
    timerTemplates: TimerTemplate[]
  ) => {
    if (!timerTemplates || targetUrl == '') {
      return [];
    }
    let compareAddress;
    if (selectedTier !== '') {
      compareAddress = selectedTier;
    } else if (selectedTower !== '') {
      compareAddress = fullString(toAddress(selectedTower));
    }
    if (selectedStack !== '') {
      compareAddress = selectedStack;
    } else if (selectedTug !== '') {
      compareAddress = selectedTug;
    }
    const validTemplates: TimerTemplate[] = [];
    for (let timerTemplate of timerTemplates) {
      for (let key in timerTemplate.action) {
        if (
          timerTemplate.action[key].targetId &&
          timerTemplate.action[key].targetId === compareAddress
        ) {
          validTemplates.push(timerTemplate);
        }
      }
    }
    return validTemplates;
  };

  const timerTemplateMap = injectionContext.pageKernel.getTimerTemplateMap();

  const sendAddTimerTemplateCommand = (timerTemplate: TimerTemplate) => {
    const connectionToServer = injectionContext.pageKernel.getConnectionToServer();
    connectionToServer.sendMessage({
      type: PageToPageServerType.ModifyTimerTemplateCommand,
      modifyTimerTemplateCommand: {
        farmId: desktopContext.selectedUrl,
        addTimer: timerTemplate,
      },
    });
  };

  const modalEnabled = modal.commandType !== null || modal.updateType !== null;

  return (
    <DraggableWindow
      title="Schedule"
      url={'Farm Data'}
      onDrag={props.onDrag}
      onDragEnd={props.onDragEnd}
      initialWindowPosition={props.initialWindowPosition}
      headerButtons={[HeaderButtonType.CLOSE]}
      headerButtonOnClicks={[() => props.closeHandler()]}
      onClick={props.onClick}
      onDragStart={props.onDragStart}
      onMouseDown={props.onMouseDown}
      selected={props.selected}
      windowType={WindowType.SETTINGS}>
      <Content className={ClassNames.bodyRegular} key={WindowType.SCHEDULER}>
        {modalEnabled && (
          <SchedulerTimerTemplateModal
            selectedTimerTemplate={selectedTimerTemplate}
            sendCommand={sendCommand}
            modal={modal}
            setModal={setModal}
            sendNewAddTimerTemplateCommand={sendAddTimerTemplateCommand}
          />
        )}
        <SchedulerDropdownMenu
          closeMenus={closeMenus}
          currentFarm={currentFarm}
          dataMap={props.dataMap}
          stackMenuIsOpen={stackMenuIsOpen}
          tierMenuIsOpen={tierMenuIsOpen}
          towerMenuIsOpen={towerMenuIsOpen}
          tugMenuIsOpen={tugMenuIsOpen}
          setStackMenuIsOpen={setStackMenuIsOpen}
          setTierMenuIsOpen={setTierMenuIsOpen}
          setTowerMenuIsOpen={setTowerMenuIsOpen}
          setTugMenuIsOpen={setTugMenuIsOpen}
          selectedStack={selectedStack}
          selectedTier={selectedTier}
          selectedTower={selectedTower}
          selectedTug={selectedTug}
          setSelectedStack={setSelectedStack}
          setSelectedTier={setSelectedTier}
          setSelectedTower={setSelectedTower}
          setSelectedTug={setSelectedTug}
        />
        <Schedule24HourBackground
          currentFarm={currentFarm}
          currentTime={currentTime}
          getCurrentTimerTemplates={getCurrentTimerTemplates}
          selectedStack={selectedStack}
          selectedTier={selectedTier}
          selectedTower={selectedTower}
          selectedTug={selectedTug}
          setSelectedStack={setSelectedStack}
          setSelectedTier={setSelectedTier}
          setSelectedTower={setSelectedTower}
          setSelectedTug={setSelectedTug}
          timerTemplateMap={timerTemplateMap}
          modal={modal}
          setModal={setModal}
          onClick={() => {
            closeMenus();
          }}
          showContextMenu={props.showContextMenu}
          hideContextMenu={props.hideContextMenu}
        />
      </Content>
    </DraggableWindow>
  );
};

export default SchedulerWindow;
