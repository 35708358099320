import _ from 'lodash';
import { CloudProduceAddress } from 'model/src/common/CloudProduceAddress';
import {
  combine,
  create,
  fullString,
  join,
  toAddress,
} from 'model/src/common/CloudProduceAddressUtility';
import { Operations, RegexPhrases } from 'model/src/common/Regex';
import { TugSubsystem } from 'model/src/common/Systems';
import { PageToPageServer } from 'model/src/dataflowprotocol/pagetopageserver/PageToPageServer';
import { PageToPageServerType } from 'model/src/dataflowprotocol/pagetopageserver/PageToPageServerType';
import { SeriesType } from 'model/src/series/SeriesTypes';
import React, { useContext } from 'react';
import { getLoggerMapFromDataMap } from 'services/src/logging/LoggerMapUtility';

import { ConfirmationBoxWindowProps } from '../../farm-ui/windows/ConfirmationBoxWindow';
import {
  ControllerContext,
  ControllerContextType,
} from '../../three/farmviewer/context/controllercontext/ControllerContext';
import {
  DesktopContext,
  DesktopContextType,
} from '../../three/farmviewer/context/desktopcontext/DesktopContext';
import {
  OperationContext,
  OperationContextType,
} from '../../three/farmviewer/context/operationcontext/OperationContext';
import WindowType from '../window/WindowTypes';
import ContextButton from './button/ContextButton';
import RaspberryPiFirmwareContextButton from './button/RaspberryPiFirmwareContextButton';
import Divider from './Divider';

export default function TugContextMenu(props) {
  const controllerContext: ControllerContextType = useContext(
    ControllerContext
  );
  const desktopContext: DesktopContextType = useContext(DesktopContext);
  const operationContext: OperationContextType = useContext(OperationContext);
  const placeTug = RegexPhrases.OPERATION + Operations.PLACEMENT;
  const tugBaseUrl: CloudProduceAddress = toAddress(props.url);

  const hasCenteredUrl: CloudProduceAddress = combine(
    tugBaseUrl,
    create([SeriesType.HAS_CENTERED])
  );
  const hasCentered: boolean = props.dataMap.all.get(fullString(hasCenteredUrl))
    .data[0].reading.value;

  const baseTugLoggersUrl: CloudProduceAddress = combine(
    tugBaseUrl,
    create(['LOGGERS'])
  );

  const loggerMap = getLoggerMapFromDataMap(baseTugLoggersUrl, props.dataMap);

  const titleCase = (string: string) => {
    return _.startCase(_.camelCase(string));
  };

  const onClickWithConfirmation = (windowProps: ConfirmationBoxWindowProps) => {
    props.hideContextMenu();
    if (desktopContext.setModal) {
      desktopContext.setModal(
        WindowType.CONFIRMATION,
        windowProps,
        desktopContext
      );
    }
  };

  return (
    <>
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(tugBaseUrl),
                  rebootCommand: {
                    dummyData: true,
                  },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
            },
            queryText: props.url + ':\n Reboot?',
          })
        }>
        Reboot
      </ContextButton>
      <ContextButton
        onClick={() =>
          onClickWithConfirmation({
            yesButtonEventHandler: () => {
              const newCommand: PageToPageServer = {
                type: PageToPageServerType.SendCommand,
                sendCommand: {
                  targetId: fullString(tugBaseUrl),
                  shutdownCommand: {
                    dummyData: true,
                  },
                },
              };
              props.connectionToServer.sendMessage(newCommand);
            },
            queryText: props.url + ':\n Shut Down?',
          })
        }>
        Shut Down
      </ContextButton>
      <Divider />
      {Array.from(loggerMap.entries()).map(([loggerName, data]) => {
        return (
          <ContextButton
            key={loggerName}
            onClick={() => {
              onClickWithConfirmation({
                yesButtonEventHandler: () => {
                  const newDataUpdate: PageToPageServer = {
                    type: PageToPageServerType.SendDataUpdate,
                    sendDataUpdate: {
                      id: fullString(data.address),
                      datum: { value: !data.isEnabled },
                    },
                  };
                  props.connectionToServer.sendMessage(newDataUpdate);
                },
                queryText:
                  props.url +
                  ':\n ' +
                  (data.isEnabled ? 'Disable ' : 'Enable ') +
                  titleCase(loggerName) +
                  '?',
              });
            }}>
            {data.isEnabled ? 'Disable ' : 'Enable '}
            {titleCase(loggerName)}
          </ContextButton>
        );
      })}
      <Divider />
      <RaspberryPiFirmwareContextButton
        baseUrl={tugBaseUrl}
        targetUrl={tugBaseUrl}
        connectionToServer={props.connectionToServer}
        dataMap={props.dataMap}
        hideContextMenu={props.hideContextMenu}
      />
      <ContextButton
        onClick={() => {
          props.inspect();
        }}>
        Inspect
      </ContextButton>
      {hasCentered && (
        <ContextButton
          onClick={() => {
            props.select(props.url, controllerContext, desktopContext);
            operationContext.setPlacementOperation(
              {
                targetUrl: tugBaseUrl,
                placementLocationUrl: undefined,
              },
              operationContext
            );
            setTimeout(() => {
              props.select(
                props.url,
                controllerContext,
                desktopContext,
                placeTug
              );
            }, 1);
          }}>
          Place Tug
        </ContextButton>
      )}
      <ContextButton
        onClick={() => {
          props.select(props.url, controllerContext, desktopContext);
        }}>
        Select
      </ContextButton>
    </>
  );
}
