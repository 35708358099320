"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertUtcToLocalSeconds = exports.convertUtcToLocalMinutes = exports.convertUtcToLocalHours = exports.convertLocalToUtcSeconds = exports.convertLocalToUtcMinutes = exports.convertLocalToUtcHours = void 0;

var Clock_1 = require("../common/Clock");

function convertLocalToUtcHours(localHours, momentUtcOffsetMinutes) {
  return localHours - momentUtcOffsetMinutes / Clock_1.MINUTES_PER_HOUR;
  "";
}

exports.convertLocalToUtcHours = convertLocalToUtcHours;

function convertLocalToUtcMinutes(localMinutes, momentUtcOffsetMinutes) {
  return localMinutes - momentUtcOffsetMinutes;
}

exports.convertLocalToUtcMinutes = convertLocalToUtcMinutes;

function convertLocalToUtcSeconds(localSeconds, momentUtcOffsetMinutes) {
  return localSeconds - momentUtcOffsetMinutes * Clock_1.SECONDS_PER_MINUTE;
}

exports.convertLocalToUtcSeconds = convertLocalToUtcSeconds;

function convertUtcToLocalHours(utcHours, momentUtcOffsetMinutes) {
  return utcHours + momentUtcOffsetMinutes / Clock_1.MINUTES_PER_HOUR;
}

exports.convertUtcToLocalHours = convertUtcToLocalHours;

function convertUtcToLocalMinutes(utcMinutes, momentUtcOffsetMinutes) {
  return utcMinutes + momentUtcOffsetMinutes;
}

exports.convertUtcToLocalMinutes = convertUtcToLocalMinutes;

function convertUtcToLocalSeconds(utcSeconds, momentUtcOffsetMinutes) {
  return utcSeconds + momentUtcOffsetMinutes * Clock_1.SECONDS_PER_MINUTE;
}

exports.convertUtcToLocalSeconds = convertUtcToLocalSeconds;