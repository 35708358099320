import React, { useContext } from 'react';
import { DataMap } from 'services/src/pagedataintake/PageDataIntake';
import styled from 'styled-components';

import { cssVar } from '../..';
import ContextualInfoBar from '../../farm-ui/controls/ContextualInfoBar';
import ContextualToolBar from '../../farm-ui/controls/ContextualToolBar';
import MetaToolBar from '../../farm-ui/controls/MetaToolBar';
import NavigatorBar from '../../farm-ui/controls/NavigatorBar';
import ConfirmationBox from '../../farm-ui/windows/ConfirmationBox';
import InspectionWindow from '../../farm-ui/windows/InspectionWindow';
import NotificationsWindow from '../../farm-ui/windows/NotificationsWindow';
import SchedulerWindow from '../../farm-ui/windows/SchedulerWindow';
import SettingsWindow from '../../farm-ui/windows/SettingsWindow';
import TreeWindow from '../../farm-ui/windows/TreeWindow';
import ViewFiltersWindow from '../../farm-ui/windows/ViewFiltersWindow';
import {
  DesktopContext,
  DesktopContextType
} from '../../three/farmviewer/context/desktopcontext/DesktopContext';
import InspectedContent from '../../three/farmviewer/windows/InspectedContent';
import WindowType from '../window/WindowTypes';
import useWindowDimensions from './useWindowDimensions';

type WindowPosition = {
  x?: number;
  y?: number;
  left?: number;
  top?: number;
  right?: number;
  bottom?: number;
};

const ClickThroughFrame = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: none;
`;

const ModalPanel = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: all;
  background-color: black;
  opacity: 0.5;
  z-index: 200;
`;

const DesktopController = props => {
  const { screenWidth, screenHeight } = useWindowDimensions();
  const desktopContext: DesktopContextType = useContext(DesktopContext);

  const modal = desktopContext.modal
  const setModal = desktopContext.setModal
  const openInspectedContent = desktopContext.openInspectedContent
  const openWindowMap = desktopContext.openWindowMap
  const setOpenWindowMap = desktopContext.setOpenWindowMap
  const windowPropsMap = desktopContext.windowPropsMap
  const removeInspectionWindow = desktopContext.removeInspectionWindow


  const getInitialPosition = (id: any) => {
    let right;
    let top;
    let windowWidth;
    switch (id) {
      case WindowType.CONFIRMATION:
        return {
          center: true,
        };
      case WindowType.SCHEDULER:
        return { //TODO(austin): calculate window position
          x: 0,
          y: 0
        }
      case WindowType.SETTINGS:
        right = +cssVar('--settingsWindowRightOffset');
        top = +cssVar('--settingsWindowTopOffset');
        windowWidth = +cssVar('--settingsWindowWidth').replace('px', '');
        return {
          x: screenWidth! - right - windowWidth,
          y: top,
        };
      case WindowType.NOTIFICATIONS:
        right = +cssVar('--notificationsWindowRightOffset');
        top = +cssVar('--notificationsWindowTopOffset');
        windowWidth = +cssVar('--notificationsWindowWidth').replace('px', '');
        return {
          x: screenWidth! - right - windowWidth,
          y: top,
        };
      case WindowType.TREE:
        return {
          x: +cssVar('--treeWindowXOffset'),
          y: +cssVar('--treeWindowYOffset'),
        };
      case WindowType.VIEW_FILTERS:
        right = +cssVar('--viewFilterWindowRightOffset');
        top = +cssVar('--viewFilterWindowTopOffset');
        windowWidth = +cssVar('--viewFilterWindowWidth').replace('px', '');
        return {
          x: screenWidth! - right - windowWidth,
          y: top,
        };
      default:
        return {
          x: 0,
          y: 0,
        };
    }
  };

  const renderWindow = (id: WindowType, selected: boolean, position: WindowPosition, dataMap: DataMap) => {
    switch (id) {
      case WindowType.SCHEDULER:
        return <SchedulerWindow
        dataMap={dataMap}
        onDragStart={(e, dragData) => {
          selectWindow(WindowType.SCHEDULER);
        }}
        onDrag={(e, dragData) => {}}
        onMouseDown={() => selectWindow(WindowType.SCHEDULER)}
        onDragEnd={() => keepInFrame(id)}
        closeHandler={() => closeWindow(WindowType.SCHEDULER)}
        key={WindowType.SCHEDULER}
        pointer-events="any"
        selected={selected}
        initialWindowPosition={position}
        showContextMenu={props.showContextMenu}
        hideContextMenu={props.hideContextMenu}
        />
      case WindowType.SETTINGS:
        return (
          <SettingsWindow
            rerender={setOpenWindowMap}
            onDragStart={(e, dragData) => {
              selectWindow(WindowType.SETTINGS);
            }}
            onDrag={(e, dragData) => {}}
            onMouseDown={() => selectWindow(WindowType.SETTINGS)}
            onDragEnd={() => keepInFrame(id)}
            closeHandler={() => closeWindow(WindowType.SETTINGS)}
            key={WindowType.SETTINGS}
            pointer-events="any"
            selected={selected}
            initialWindowPosition={position}
          />
          );
      case WindowType.NOTIFICATIONS:
        return (
          <NotificationsWindow
            onDragStart={(e, dragData) => {
              selectWindow(WindowType.NOTIFICATIONS);
            }}
            id={id}
            onDrag={(e, dragData) => {}}
            onMouseDown={() => selectWindow(WindowType.NOTIFICATIONS)}
            onDragEnd={() => keepInFrame(id)}
            closeHandler={() => closeWindow(WindowType.NOTIFICATIONS)}
            key={WindowType.NOTIFICATIONS}
            selected={selected}
            initialWindowPosition={position}
          />
        );
      case WindowType.TREE:
        return (
          <TreeWindow
          id={id}
            onDragStart={(e, dragData) => {
              selectWindow(WindowType.TREE);
            }}
            onDrag={(e, dragData) => {}}
            onMouseDown={() => selectWindow(WindowType.TREE)}
            onDragEnd={() => keepInFrame(id)}
            closeHandler={() => closeWindow(WindowType.TREE)}
            key={WindowType.TREE}
            selected={selected}
            initialWindowPosition={position}
            dataMap={dataMap}
          />
        );
      case WindowType.VIEW_FILTERS:
        return (
          <ViewFiltersWindow
          id={id}
            onDragStart={(e, dragData) => {
              selectWindow(WindowType.VIEW_FILTERS);
            }}
            onDrag={(e, dragData) => {}}
            onMouseDown={() => selectWindow(WindowType.VIEW_FILTERS)}
            onDragEnd={() => keepInFrame(id)}
            closeHandler={() => closeWindow(WindowType.VIEW_FILTERS)}
            key={WindowType.VIEW_FILTERS}
            selected={selected}
            initialWindowPosition={position}
            dataMap={dataMap}
          />
        );
      default:
        if (openInspectedContent.get(id)) {
          const windowProps = windowPropsMap.get(id);
          const initialPosition = openInspectedContent.get(id)?.initialWindowPosition.x === 0 
            ? windowProps.initialWindowPosition
            : openInspectedContent.get(id)?.initialWindowPosition;
          return (
            <InspectionWindow
            id={id}
              {...windowProps}
              initialWindowPosition={initialPosition}
              onDragStart={(e, dragData) => {
                selectWindow(id);
              }}
              onMouseDown={() => {
                selectWindow(id);
              }}
              onDragEnd={() => keepInFrame(id)}
              onDrag={(e, dragData) => {
                windowProps.onDrag(e, dragData);
              }}
              closeHandler={() => {
                windowProps.closeHandler();
                closeWindow(id);
              }}
              key={id}
              selected={selected}
              >
              <InspectedContent
                {...openInspectedContent.get(id)}
                connectionToServer={props.connectionToServer}
              />
            </InspectionWindow>
          );
        }
        break;
    }
  };

  const getModal = (id: any, windowPropsMap: any, closeHandler: () => void, windowProps?: any) => {
    switch (id) {
      case '':
        break;
      case WindowType.CONFIRMATION:
        const currentWindowProps = windowProps
          ? windowProps
          : windowPropsMap.get(id, desktopContext)
          ? windowPropsMap.get(id, desktopContext)
          : {};
        return (
          <ConfirmationBox
            closeHandler={() => {
              closeHandler();
              setModal!('', {}, desktopContext);
            }}
            key={id}
            pointer-events="any"
            initialWindowPosition={getInitialPosition(id)}
            {...currentWindowProps}
          />
        );
    }
  };

  const openWindow = (id: any) => {
    setOpenWindowMap.set(id, getInitialPosition(id), desktopContext);
  };

  const closeWindow = (id: any) => {
    if (openWindowMap.has(id)) {
      updateInspectionPosition(id);
      removeInspectionWindow!(id, desktopContext);
    }
  };
  const keepInFrame = (id: any) => {
    if (openWindowMap.has(id)) {
      const position = openWindowMap.get(id);
      if (position && position.y && position.y < +cssVar('--upperScreenHeightPadding')) {
        position.y = +cssVar('--upperScreenHeightPadding')
        updateInspectionPosition(id);
        setOpenWindowMap.setPosition(id, position, desktopContext)
      }
    }
  };
  const updateInspectionPosition =(id: any, newPosition?: WindowPosition) => {
    if (openWindowMap.get(id) && openInspectedContent.get(id)) {
      let position;
      if (newPosition) {
        position = newPosition
      } else {
        position = openWindowMap.get(id);
      }
      props.setInspectionWindowPosition( {
        url: id,
        x: position.x,
        y: position.y
      });
    }
  };


  const toggleWindow = (id: any) => {
    if (!!desktopContext.openWindowMap.get(id)) {
      closeWindow(id);
    } else {
      openWindow(id);
    }
  };
  const selectWindow = (id: any) => {
    if (!!desktopContext.openWindowMap.get(id)) {
      const position = desktopContext.openWindowMap.get(id)!;
      setOpenWindowMap.remove(id, desktopContext);
      setOpenWindowMap.set(id, position, desktopContext); 
    }
  };

  const isOpen = (id:any) => {
    return !!desktopContext.openWindowMap.get(id);
  };

  const modalRender = getModal(
    modal,
    desktopContext.windowPropsMap,
    props.closeHandler, 
    desktopContext.windowPropsMap.get(modal)
  );

  return (
    <>
      <ClickThroughFrame>
        <ContextualInfoBar />
        <NavigatorBar select={props.select} />
        <ContextualToolBar
          toggleWindow={id => toggleWindow(id)}
          isOpen={id => isOpen(id)}
        />
        <MetaToolBar
          toggleWindow={id => toggleWindow(id)}
          isOpen={id => isOpen(id)}
        />
        {!!openWindowMap.size &&
          Array.from(openWindowMap.entries()).map((windowEntry, index) =>
          renderWindow(
              windowEntry![0],
              index == openWindowMap.size - 1,
              windowEntry![1],
              props.dataMap
            )
          )}
      </ClickThroughFrame>
      {modal && (
        <>
          <ModalPanel />
          {modalRender}
        </>
      )}
    </>
  );
};

export default DesktopController;

DesktopController.defaultProps = {
  windows: new Map([
    [WindowType.SETTINGS, <SettingsWindow />],
    [WindowType.NOTIFICATIONS, <NotificationsWindow />],
    [WindowType.TREE, <TreeWindow />],
  ]),
};
