import { CloudProduceAddress } from 'model/src/common/CloudProduceAddress';
import { combine, create } from 'model/src/common/CloudProduceAddressUtility';
import { SumpSubsystem, TowerSubsystem } from 'model/src/common/Systems';
import { Data } from 'model/src/dataflowprotocol/Datum';
import { AcStateReading } from 'model/src/series/AcStateReading';
import { BatteryLevelReading } from 'model/src/series/BatteryLevelReading';
import { BooleanReading } from 'model/src/series/BooleanReading';
import { DockStatusReading } from 'model/src/series/DockStatusReading';
import {
  NumericalReading,
  unitToString,
} from 'model/src/series/NumericalReading';
import { SeriesType } from 'model/src/series/SeriesTypes';
import { StringReading } from 'model/src/series/StringReading';
import { ValveStateReading } from 'model/src/series/ValveStateReading';
import { PumpStatus } from 'model/src/status/PumpStatus';
import { BuiltinType } from 'model/src/typescript/Typescript';
import React from 'react';
import styled from 'styled-components';

import ClassNames from '../../ClassNames';
import { useDataMap } from '../../three/farmviewer/context/datamapcontext/useDataMap';
import InspectionDataDivider from '../dividers/InspectionDataDivider';

export type TowerDataProps = {
  url: CloudProduceAddress;

  batteryLevel: Data<BatteryLevelReading>;
  connectionId: Data<StringReading>;
  isConnected: Data<BooleanReading>;
  dockStatus: Data<DockStatusReading>;
  homeSquare: Data<StringReading>;
  homePositionX: Data<NumericalReading>;
  homePositionY: Data<NumericalReading>;
  homePositionZ: Data<NumericalReading>;
  isAcPresent: Data<BooleanReading>;
  isCharging: Data<BooleanReading>;
  positionX: Data<NumericalReading>;
  positionY: Data<NumericalReading>;
  positionZ: Data<NumericalReading>;
  pumpStatus: Data<PumpStatus>;
  rotationX: Data<NumericalReading>;
  rotationY: Data<NumericalReading>;
  rotationZ: Data<NumericalReading>;
  towerIngressFlowLM: Data<NumericalReading>;
  towerIngressTotalLiters: Data<NumericalReading>;
  towerEgressFlowLM: Data<NumericalReading>;
  towerEgressTotalLiters: Data<NumericalReading>;
  umbilicalAcState: Data<AcStateReading>;
  umbilicalConnectedState: Data<StringReading>;
  umbilicalValveState: Data<ValveStateReading>;
  umbilicalPositionX: Data<NumericalReading>;
  umbilicalPositionY: Data<NumericalReading>;
  umbilicalPositionZ: Data<NumericalReading>;
};

const TowerDataContainer = styled.div`
  color: var(--black);
  padding: var(--windowPadding);
  padding-top: 0;
  width: var(--tierInspectionWindowWidth);
`;

const KeyFormatting = styled.div`
  float: left;
`;
const Key = props => (
  <KeyFormatting className={ClassNames.labelRegular}>
    {props.children}
  </KeyFormatting>
);

const ValueFormatting = styled.div`
  margin-left: auto;
  order: 2;
`;
const Value = props => (
  <ValueFormatting className={ClassNames.labelMedium}>
    {props.children}
  </ValueFormatting>
);

const FlexBarKey = props => (
  <div className={ClassNames.labelRegular}>{props.children}</div>
);

const FlexBarValue = props => (
  <div style={{ width: '25%' }} className={ClassNames.labelMedium}>
    {props.children}
  </div>
);

const KeyValueFlexBar = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

const DatumContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const getStringValue = stream => {
  if (
    !stream.data[0] ||
    !stream.data[0].reading ||
    !stream.data[0].reading.value
  ) {
    return 'Not found';
  }
  return stream.data[0].reading.value;
};

const getBooleanValue = stream => {
  if (
    !stream.data[0] ||
    !stream.data[0].reading ||
    stream.data[0].reading.value === BuiltinType.UNDEFINED
  ) {
    return 'Not found';
  }
  return typeof stream.data[0].reading.value == BuiltinType.BOOLEAN
    ? stream.data[0].reading.value
      ? 'True'
      : 'False'
    : 'Not found';
};
const getNumericalValue = (stream, decimals?) => {
  if (
    typeof stream.data[0] === BuiltinType.UNDEFINED ||
    typeof stream.data[0].reading === BuiltinType.UNDEFINED ||
    typeof stream.data[0].reading.value === BuiltinType.UNDEFINED ||
    stream.data[0].reading.value === null
  ) {
    return 'Not found';
  }
  if (typeof decimals === BuiltinType.NUMBER) {
    return (
      stream.data[0].reading.value.toFixed(decimals).toString() +
      ' ' +
      unitToString(stream.data[0].reading.unit)
    );
  }
  return (
    stream.data[0].reading.value.toString() +
    ' ' +
    unitToString(stream.data[0].reading.unit)
  );
};

const getStatusValue = stream => {
  if (!stream.data[0] || !stream.data[0].reading) {
    return 'Not found';
  }
  return stream.data[0].reading.value
    ? stream.data[0].reading.value
    : stream.data[0].reading.status; // TODO(philipp), ISSUE(1): Remove refs to status after migration
};

const getValveStateValue = stream => {
  if (!stream.data[0] || !stream.data[0].reading) {
    return 'Not found';
  }
  return stream.data[0].reading.value
    ? stream.data[0].reading.value
    : stream.data[0].reading.valveState; // TODO(philipp), ISSUE(1): Remove refs to status after migration
};

const getBatteryIsChargingValue = stream => {
  if (
    !stream.data[0] ||
    !stream.data[0].reading ||
    typeof stream.data[0].reading.isCharging === BuiltinType.UNDEFINED
  ) {
    return 'Not found';
  }
  return typeof stream.data[0].reading.isCharging == BuiltinType.BOOLEAN
    ? stream.data[0].reading.isCharging
      ? 'True'
      : 'False'
    : 'Not found';
};

const TowerData = (props: TowerDataProps) => {
  const pumpPwm = useDataMap(
    //TODO(austin): move to subcomponent
    combine(
      props.url,
      create([TowerSubsystem.SUMP, SumpSubsystem.PUMP, SeriesType.PWM])
    )
  );
  const pumpPwmString = pumpPwm.value + ' ' + unitToString(pumpPwm.unit);
  const pumpUpperLevelSwitch = useDataMap(
    //TODO(austin): move to subcomponent
    combine(
      props.url,
      create([
        TowerSubsystem.SUMP,
        SumpSubsystem.PUMP,
        SeriesType.UPPER_LEVEL_SWITCH,
      ])
    )
  );
  const pumpUpperLevelSwitchString = pumpUpperLevelSwitch.value.toString();
  const pumpLowerLevelSwitch = useDataMap(
    //TODO(austin): move to subcomponent
    combine(
      props.url,
      create([
        TowerSubsystem.SUMP,
        SumpSubsystem.PUMP,
        SeriesType.LOWER_LEVEL_SWITCH,
      ])
    )
  );
  const pumpLowerLevelSwitchString = pumpLowerLevelSwitch.value.toString();
  return (
    <TowerDataContainer className={ClassNames.bodyRegular}>
      <DatumContainer>
        <Key>AC: </Key>
        <Value>{getStringValue(props.isAcPresent)}</Value>
      </DatumContainer>
      <InspectionDataDivider />
      <DatumContainer>
        <Key>Battery: </Key>
        <Value>{getNumericalValue(props.batteryLevel)}</Value>
      </DatumContainer>
      <DatumContainer>
        <Key>Charging: </Key>
        <Value>{getBooleanValue(props.isCharging)}</Value>
      </DatumContainer>
      <InspectionDataDivider />
      <DatumContainer>
        <Key>Dock Status:</Key>
        <Value>{getStatusValue(props.dockStatus)}</Value>
      </DatumContainer>
      <InspectionDataDivider />
      <DatumContainer>
        <Key>Pump Status:</Key>
        <Value>{getStatusValue(props.pumpStatus)}</Value>
      </DatumContainer>
      <DatumContainer>
        <Key>Pump PWM:</Key>
        <Value>{pumpPwmString}</Value>
      </DatumContainer>
      <DatumContainer>
        <Key>Pump Upper Level Switch:</Key>
        <Value>{pumpUpperLevelSwitchString}</Value>
      </DatumContainer>
      <DatumContainer>
        <Key>Pump Lower Level Switch:</Key>
        <Value>{pumpLowerLevelSwitchString}</Value>
      </DatumContainer>
      <InspectionDataDivider />
      <DatumContainer>
        <Key>Tower Ingress:</Key>
        <Value>{getNumericalValue(props.towerIngressFlowLM, 2)}</Value>
      </DatumContainer>
      <DatumContainer>
        <Key>Tower Ingress Total:</Key>
        <Value>{getNumericalValue(props.towerIngressTotalLiters, 2)}</Value>
      </DatumContainer>
      <InspectionDataDivider />
      <DatumContainer>
        <Key>Tower Egress:</Key>
        <Value>{getNumericalValue(props.towerEgressFlowLM, 2)}</Value>
      </DatumContainer>
      <DatumContainer>
        <Key>Tower Egress Total:</Key>
        <Value>{getNumericalValue(props.towerEgressTotalLiters, 2)}</Value>
      </DatumContainer>
      <InspectionDataDivider />
      <DatumContainer>
        <Key>Umbilical AC State: </Key>
        <Value>{getStringValue(props.umbilicalAcState)}</Value>
      </DatumContainer>
      <DatumContainer>
        <Key>Umbilical Connected State: </Key>
        <Value>{getStringValue(props.umbilicalConnectedState)}</Value>
      </DatumContainer>
      <DatumContainer>
        <Key>Umbilical Valve State: </Key>
        <Value>{getValveStateValue(props.umbilicalValveState)}</Value>
      </DatumContainer>
      <InspectionDataDivider />
      <DatumContainer>
        <KeyValueFlexBar>
          <FlexBarKey>Umbilical Postion: </FlexBarKey>
          <FlexBarValue>
            X: {getNumericalValue(props.umbilicalPositionX, 0)}
          </FlexBarValue>
          <FlexBarValue>
            Y: {getNumericalValue(props.umbilicalPositionY, 0)}
          </FlexBarValue>
          <FlexBarValue>
            Z: {getNumericalValue(props.umbilicalPositionZ, 0)}
          </FlexBarValue>
        </KeyValueFlexBar>
      </DatumContainer>
      <InspectionDataDivider />
      <DatumContainer>
        <KeyValueFlexBar>
          <FlexBarKey>Postion: </FlexBarKey>
          <FlexBarValue>
            X: {getNumericalValue(props.positionX, 0)}
          </FlexBarValue>
          <FlexBarValue>
            Y: {getNumericalValue(props.positionY, 0)}
          </FlexBarValue>
          <FlexBarValue>
            Z: {getNumericalValue(props.positionZ, 0)}
          </FlexBarValue>
        </KeyValueFlexBar>
      </DatumContainer>
      <InspectionDataDivider />
      <DatumContainer>
        <KeyValueFlexBar>
          <FlexBarKey>Tower Rotation: </FlexBarKey>
          <FlexBarValue>
            X: {getNumericalValue(props.rotationX, 0)}
          </FlexBarValue>
          <FlexBarValue>
            Y: {getNumericalValue(props.rotationY, 0)}
          </FlexBarValue>
          <FlexBarValue>
            Z: {getNumericalValue(props.rotationZ, 0)}
          </FlexBarValue>
        </KeyValueFlexBar>
      </DatumContainer>
      <InspectionDataDivider />
      <DatumContainer>
        <Key>Tower Home:</Key>
        <Value>{getStringValue(props.homeSquare)}</Value>
      </DatumContainer>
      <InspectionDataDivider />
      <DatumContainer>
        <FlexBarKey>Tower Home Postion: </FlexBarKey>
        <FlexBarValue>
          X: {getNumericalValue(props.homePositionX, 0)}
        </FlexBarValue>
        <FlexBarValue>
          Y: {getNumericalValue(props.homePositionY, 0)}
        </FlexBarValue>
        <FlexBarValue>
          Z: {getNumericalValue(props.homePositionZ, 0)}
        </FlexBarValue>
      </DatumContainer>
      <InspectionDataDivider />
      <DatumContainer>
        <Key>Connected to Tug:</Key>
        <Value>{getBooleanValue(props.isConnected)}</Value>
      </DatumContainer>
      <DatumContainer>
        <Key>Tug Connection ID:</Key>
        <Value>{getStringValue(props.connectionId)}</Value>
      </DatumContainer>
    </TowerDataContainer>
  );
};

export default TowerData;
