import * as _ from 'lodash';
import {
  combine,
  create,
  fullString,
} from 'model/src/common/CloudProduceAddressUtility';
import { StackFlowSensors, StackSubsystem } from 'model/src/common/Systems';
import { Data } from 'model/src/dataflowprotocol/Datum';
import {
  NumericalReading,
  unitToString,
} from 'model/src/series/NumericalReading';
import { SeriesType } from 'model/src/series/SeriesTypes';
import { BuiltinType } from 'model/src/typescript/Typescript';
import { title } from 'process';
import React from 'react';
import styled from 'styled-components';
import util from 'util';

import { cssVar } from '../..';
import ClassNames from '../../ClassNames';
import AnalogIndicator from '../analog-indicator/AnalogIndicator';
import InspectionDataDivider from '../dividers/InspectionDataDivider';

const InlineDisplay = styled.div(`
  display: inline;
  `);

const StackDataContainer = styled.div`
  color: var(--black);
  padding: var(--windowPadding);
  padding-top: 0;
  width: var(--tierInspectionWindowWidth);
  display: inline-block;
`;

const KeyFormatting = styled.div`
  float: left;
`;
const Key = props => (
  <KeyFormatting className={ClassNames.labelRegular}>
    {props.children}
  </KeyFormatting>
);

const ValueFormatting = styled.div`
  margin-left: auto;
  order: 2;
`;
const Value = props => (
  <ValueFormatting className={ClassNames.labelMedium}>
    {props.children}
  </ValueFormatting>
);

const DatumContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Stacked = styled.div`
  display: block;
`;

const StackedValue = props => (
  <Stacked>
    <div style={{ float: 'right' }}>{props.children}</div>
  </Stacked>
);

export type FlowSensorData = {
  waterFlow10SecondAverage: Data<NumericalReading>;
  waterFlowInstant: Data<NumericalReading>;
  waterFlowTotalVolume: Data<NumericalReading>;
};

export type StackDataProps = {
  airPressure: Data<NumericalReading>;
  flowAtEndOfBlue: FlowSensorData;
  flowAtEndOfRed: FlowSensorData;
  flowFromRedToTowers: FlowSensorData;
  flowFromTank: FlowSensorData;
  flowIntoBlueFromTowers: FlowSensorData;
  flowIntoRed: FlowSensorData;
  flowToFilter: FlowSensorData;
  flowToOverflow: FlowSensorData;
  lightActualPoint: Data<NumericalReading>;
  lightSetPoint: Data<NumericalReading>;
  pwm: Data<NumericalReading>;
  waterLevel: Data<NumericalReading>;
  waterPresent: boolean;
  waterTemperature: Data<NumericalReading>;
};

const titleCase = (string: string) => {
  return _.startCase(_.camelCase(string));
};

const getNumericalValue = (stream: Record<string, any>, decimals?: number) => {
  if (
    typeof stream.data[0] === BuiltinType.UNDEFINED ||
    typeof !stream.data[0].reading === BuiltinType.UNDEFINED ||
    typeof stream.data[0].reading.value === BuiltinType.UNDEFINED
  ) {
    return 'Not found';
  }
  if (typeof decimals === BuiltinType.NUMBER) {
    return (
      stream.data[0].reading.value.toFixed(decimals).toString() +
      ' ' +
      unitToString(stream.data[0].reading.unit)
    );
  }
  return (
    stream.data[0].reading.value.toString() +
    ' ' +
    unitToString(stream.data[0].reading.unit)
  );
};
const getStringValue = (stream: Record<string, any>) => {
  if (
    typeof stream.data[0] === BuiltinType.UNDEFINED ||
    typeof !stream.data[0].reading === BuiltinType.UNDEFINED ||
    typeof stream.data[0].reading.value === BuiltinType.UNDEFINED
  )
    return 'Not found';

  return titleCase(stream.data[0].reading.value);
};

const getValveStateValue = (stream: Record<string, any>) => {
  if (!stream.data[0] || !stream.data[0].reading) {
    return 'Not found';
  }
  return stream.data[0].reading.value
    ? stream.data[0].reading.value
    : stream.data[0].reading.valveState; // TODO(philipp), ISSUE(1): Remove refs to status after migration
};

const getPumpStatusValue = (stream: Record<string, any>) => {
  if (!stream.data[0] || !stream.data[0].reading) {
    return 'Not found';
  }
  return stream.data[0].reading.value
    ? stream.data[0].reading.value
    : stream.data[0].reading.pumpStatus; // TODO(philipp), ISSUE(1): Remove refs to status after migration
};
const StackData = props => {
  return (
    <InlineDisplay>
      <StackDataContainer className={ClassNames.bodyRegular}>
        <DatumContainer>
          <Key>Air Pressure: </Key>
          <Value>{getNumericalValue(props.airPressure, 2)}</Value>
        </DatumContainer>
        <InspectionDataDivider />
        <DatumContainer>
          <Key>Light Set Point:</Key>
          <Value>{getNumericalValue(props.lightSetPoint, 2)}</Value>
        </DatumContainer>
        <DatumContainer>
          <Key>Light Actual:</Key>
          <Value>{getNumericalValue(props.lightActualPoint, 2)}</Value>
        </DatumContainer>
        <InspectionDataDivider />
        <DatumContainer>
          <Key>Pump Status:</Key>
          <Value>{getPumpStatusValue(props.pumpStatus)}</Value>
        </DatumContainer>
        <DatumContainer>
          <Key>Pump Current:</Key>
          <Value>{getNumericalValue(props.pumpCurrent)}</Value>
        </DatumContainer>
        <DatumContainer>
          <Key>PWM:</Key>
          <Value>{getNumericalValue(props.pwm, 0)}</Value>
        </DatumContainer>
        <InspectionDataDivider />
        <DatumContainer>
          <Key>Stack Valve State:</Key>
          <Value>{getStringValue(props.valveState)}</Value>
        </DatumContainer>
        <DatumContainer>
          <Key>Drain Valve State:</Key>
          <Value>{getStringValue(props.drainValveState)}</Value>
        </DatumContainer>
        <InspectionDataDivider />
        <DatumContainer>
          <Key>Stack Pump Controlled Variable:</Key>
          <Value>{getStringValue(props.controlledVariable)}</Value>
        </DatumContainer>
        <InspectionDataDivider />
        <DatumContainer>
          <Key>Tank Water Level:</Key>
          <Value>{getNumericalValue(props.waterLevel, 2)}</Value>
        </DatumContainer>
        <InspectionDataDivider />
        <DatumContainer>
          <Key>Tank Water Temperature:</Key>
          <Value>{getNumericalValue(props.waterTemperature, 2)}</Value>
          <Value>
            <AnalogIndicator
              scale={0.8}
              url={fullString(
                combine(
                  props.parentAddress,
                  create([
                    StackSubsystem.TEMPERATURE_SENSOR,
                    '1',
                    SeriesType.WATER_TEMPERATURE,
                  ])
                )
              )}
            />
          </Value>
        </DatumContainer>
        <InspectionDataDivider />
        <DatumContainer>
          <Key>
            <Stacked>Flow At End Of Blue</Stacked>
            <Stacked>Instant Flow:</Stacked>
            <Stacked>10 Second Average:</Stacked>
            <Stacked>Total Volume Since Reset:</Stacked>
          </Key>
          <Value>
            <Stacked>
              <div style={{ height: cssVar('--inspectionWindowLineHeight') }} />
            </Stacked>
            <StackedValue>
              {getNumericalValue(props.flowAtEndOfBlue.waterFlowInstant, 2)}
            </StackedValue>
            <StackedValue>
              {getNumericalValue(
                props.flowAtEndOfBlue.waterFlow10SecondAverage,
                2
              )}
            </StackedValue>
            <StackedValue>
              {getNumericalValue(props.flowAtEndOfBlue.waterFlowTotalVolume, 2)}
            </StackedValue>
          </Value>
          <Value>
            <AnalogIndicator
              scale={0.8}
              url={fullString(
                combine(
                  props.parentAddress,
                  create([
                    StackSubsystem.WATER_FLOW_SENSOR,
                    StackFlowSensors.FLOW_AT_END_OF_BLUE.toString(),
                    SeriesType.WATER_FLOW_10_SECOND_AVERAGE,
                  ])
                )
              )}
            />
          </Value>
        </DatumContainer>
        <InspectionDataDivider />
        <DatumContainer>
          <Key>
            <Stacked>Flow At End Of Red</Stacked>
            <Stacked>Instant Flow:</Stacked>
            <Stacked>10 Second Average:</Stacked>
            <Stacked>Total Volume Since Reset:</Stacked>
          </Key>
          <Value>
            <Stacked>
              <div style={{ height: cssVar('--inspectionWindowLineHeight') }} />
            </Stacked>
            <StackedValue>
              {getNumericalValue(props.flowAtEndOfRed.waterFlowInstant, 2)}
            </StackedValue>
            <StackedValue>
              {getNumericalValue(
                props.flowAtEndOfRed.waterFlow10SecondAverage,
                2
              )}
            </StackedValue>
            <StackedValue>
              {getNumericalValue(props.flowAtEndOfRed.waterFlowTotalVolume, 2)}
            </StackedValue>
          </Value>
          <Value />
          <Value>
            <AnalogIndicator
              scale={0.8}
              url={fullString(
                combine(
                  props.parentAddress,
                  create([
                    StackSubsystem.WATER_FLOW_SENSOR,
                    StackFlowSensors.FLOW_AT_END_OF_RED.toString(),
                    SeriesType.WATER_FLOW_10_SECOND_AVERAGE,
                  ])
                )
              )}
            />
          </Value>
        </DatumContainer>
      </StackDataContainer>
      <StackDataContainer className={ClassNames.bodyRegular}>
        <DatumContainer>
          <Key>
            <Stacked>Flow From Red To Towers</Stacked>
            <Stacked>Instant Flow:</Stacked>
            <Stacked>10 Second Average:</Stacked>
            <Stacked>Total Volume Since Reset:</Stacked>
          </Key>
          <Value>
            <Stacked>
              <div style={{ height: cssVar('--inspectionWindowLineHeight') }} />
            </Stacked>
            <StackedValue>
              {getNumericalValue(props.flowFromRedToTowers.waterFlowInstant, 2)}
            </StackedValue>
            <StackedValue>
              {getNumericalValue(
                props.flowFromRedToTowers.waterFlow10SecondAverage,
                2
              )}
            </StackedValue>
            <StackedValue>
              {getNumericalValue(
                props.flowFromRedToTowers.waterFlowTotalVolume,
                2
              )}
            </StackedValue>
          </Value>
          <Value>
            <AnalogIndicator
              scale={0.8}
              url={fullString(
                combine(
                  props.parentAddress,
                  create([
                    StackSubsystem.WATER_FLOW_SENSOR,
                    StackFlowSensors.FLOW_FROM_RED_TO_TOWERS.toString(),
                    SeriesType.WATER_FLOW_10_SECOND_AVERAGE,
                  ])
                )
              )}
            />
          </Value>
        </DatumContainer>
        <InspectionDataDivider />
        <DatumContainer>
          <Key>
            <Stacked>Flow From Tank</Stacked>
            <Stacked>Instant Flow:</Stacked>
            <Stacked>10 Second Average:</Stacked>
            <Stacked>Total Volume Since Reset:</Stacked>
          </Key>
          <Value>
            <Stacked>
              <div style={{ height: cssVar('--inspectionWindowLineHeight') }} />
            </Stacked>
            <StackedValue>
              {getNumericalValue(props.flowFromTank.waterFlowInstant, 2)}
            </StackedValue>
            <StackedValue>
              {getNumericalValue(
                props.flowFromTank.waterFlow10SecondAverage,
                2
              )}
            </StackedValue>
            <StackedValue>
              {getNumericalValue(props.flowFromTank.waterFlowTotalVolume, 2)}
            </StackedValue>
          </Value>
          <Value>
            <AnalogIndicator
              scale={0.8}
              url={fullString(
                combine(
                  props.parentAddress,
                  create([
                    StackSubsystem.WATER_FLOW_SENSOR,
                    StackFlowSensors.FLOW_FROM_TANK.toString(),
                    SeriesType.WATER_FLOW_10_SECOND_AVERAGE,
                  ])
                )
              )}
            />
          </Value>
        </DatumContainer>
        <InspectionDataDivider />
        <DatumContainer>
          <Key>
            <Stacked>Flow Into Blue From Towers</Stacked>
            <Stacked>Instant Flow:</Stacked>
            <Stacked>10 Second Average:</Stacked>
            <Stacked>Total Volume Since Reset:</Stacked>
          </Key>
          <Value>
            <Stacked>
              <div style={{ height: cssVar('--inspectionWindowLineHeight') }} />
            </Stacked>
            <StackedValue>
              {getNumericalValue(
                props.flowIntoBlueFromTowers.waterFlowInstant,
                2
              )}
            </StackedValue>
            <StackedValue>
              {getNumericalValue(
                props.flowIntoBlueFromTowers.waterFlow10SecondAverage,
                2
              )}
            </StackedValue>
            <StackedValue>
              {getNumericalValue(
                props.flowIntoBlueFromTowers.waterFlowTotalVolume,
                2
              )}
            </StackedValue>
          </Value>
          <Value>
            <AnalogIndicator
              scale={0.8}
              url={fullString(
                combine(
                  props.parentAddress,
                  create([
                    StackSubsystem.WATER_FLOW_SENSOR,
                    StackFlowSensors.FLOW_INTO_BLUE_FROM_TOWERS.toString(),
                    SeriesType.WATER_FLOW_10_SECOND_AVERAGE,
                  ])
                )
              )}
            />
          </Value>
        </DatumContainer>
        <InspectionDataDivider />
        <DatumContainer>
          <Key>
            <Stacked>Flow Into Red</Stacked>
            <Stacked>Instant Flow:</Stacked>
            <Stacked>10 Second Average:</Stacked>
            <Stacked>Total Volume Since Reset:</Stacked>
          </Key>
          <Value>
            <Stacked>
              <div style={{ height: cssVar('--inspectionWindowLineHeight') }} />
            </Stacked>
            <StackedValue>
              {getNumericalValue(props.flowIntoRed.waterFlowInstant, 2)}
            </StackedValue>
            <StackedValue>
              {getNumericalValue(props.flowIntoRed.waterFlow10SecondAverage, 2)}
            </StackedValue>
            <StackedValue>
              {getNumericalValue(props.flowIntoRed.waterFlowTotalVolume, 2)}
            </StackedValue>
          </Value>
          <Value>
            <AnalogIndicator
              scale={0.8}
              url={fullString(
                combine(
                  props.parentAddress,
                  create([
                    StackSubsystem.WATER_FLOW_SENSOR,
                    StackFlowSensors.FLOW_INTO_RED.toString(),
                    SeriesType.WATER_FLOW_10_SECOND_AVERAGE,
                  ])
                )
              )}
            />
          </Value>
        </DatumContainer>
        <InspectionDataDivider />
        <DatumContainer>
          <Key>
            <Stacked>Flow To Filter</Stacked>
            <Stacked>Instant Flow:</Stacked>
            <Stacked>10 Second Average:</Stacked>
            <Stacked>Total Volume Since Reset:</Stacked>
          </Key>
          <Value>
            <Stacked>
              <div style={{ height: cssVar('--inspectionWindowLineHeight') }} />
            </Stacked>
            <StackedValue>
              {getNumericalValue(props.flowToFilter.waterFlowInstant, 2)}
            </StackedValue>
            <StackedValue>
              {getNumericalValue(
                props.flowToFilter.waterFlow10SecondAverage,
                2
              )}
            </StackedValue>
            <StackedValue>
              {getNumericalValue(props.flowToFilter.waterFlowTotalVolume, 2)}
            </StackedValue>
          </Value>
          <Value>
            <AnalogIndicator
              scale={0.8}
              url={fullString(
                combine(
                  props.parentAddress,
                  create([
                    StackSubsystem.WATER_FLOW_SENSOR,
                    StackFlowSensors.FLOW_TO_FILTER.toString(),
                    SeriesType.WATER_FLOW_10_SECOND_AVERAGE,
                  ])
                )
              )}
            />
          </Value>
        </DatumContainer>
        <InspectionDataDivider />
        <DatumContainer>
          <Key>
            <Stacked>Flow To Overflow</Stacked>
            <Stacked>Instant Flow:</Stacked>
            <Stacked>10 Second Average:</Stacked>
            <Stacked>Total Volume Since Reset:</Stacked>
          </Key>
          <Value>
            <Stacked>
              <div style={{ height: cssVar('--inspectionWindowLineHeight') }} />
            </Stacked>
            <StackedValue>
              {getNumericalValue(props.flowToOverflow.waterFlowInstant, 2)}
            </StackedValue>
            <StackedValue>
              {getNumericalValue(
                props.flowToOverflow.waterFlow10SecondAverage,
                2
              )}
            </StackedValue>
            <StackedValue>
              {getNumericalValue(props.flowToOverflow.waterFlowTotalVolume, 2)}
            </StackedValue>
          </Value>
          <Value>
            <AnalogIndicator
              scale={0.8}
              url={fullString(
                combine(
                  props.parentAddress,
                  create([
                    StackSubsystem.WATER_FLOW_SENSOR,
                    StackFlowSensors.FLOW_TO_OVERFLOW.toString(),
                    SeriesType.WATER_FLOW_10_SECOND_AVERAGE,
                  ])
                )
              )}
            />
          </Value>
        </DatumContainer>
      </StackDataContainer>
    </InlineDisplay>
  );
};
export default StackData;
