enum WindowType {
  CONFIRMATION = 'CONFIRMATION',
  INSPECTION = 'INSPECTION',
  NOTIFICATIONS = 'NOTIFICATIONS',
  SCHEDULER = 'SCHEDULER',
  SETTINGS = 'SETTINGS',
  TREE = 'TREE',
  VIEW_FILTERS = 'VIEW_FILTERS',
}

export default WindowType;
