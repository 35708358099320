import { BooleanReading } from 'model/src/series/BooleanReading';
import {
  NumericalReading,
  unitToString,
} from 'model/src/series/NumericalReading';
import { PumpStatusReading } from 'model/src/series/PumpStatusReading';
import { BuiltinType } from 'model/src/typescript/Typescript';
import React from 'react';
import styled from 'styled-components';

import ClassNames from '../../ClassNames';
import InspectionDataDivider from '../dividers/InspectionDataDivider';

export type SumpDataProps = {
  accumulationTimeS: NumericalReading;
  batteryLevelV: NumericalReading;
  batteryLevelLowFromLedConnector: BooleanReading;
  batteryLevelLowFromRelay: BooleanReading;
  egressFlowLM: NumericalReading;
  egressFlow10SecondAverageLM: NumericalReading;
  egressFlowTotalLitersSinceReset: NumericalReading;
  ingressFlowLM: NumericalReading;
  ingressFlow10SecondAverageLM: NumericalReading;
  ingressFlowTotalLitersSinceReset: NumericalReading;
  isPumpRunning: NumericalReading;
  maxPwm: NumericalReading;
  override: BooleanReading;
  pumpElectricCurrent: NumericalReading;
  pumpStatus: PumpStatusReading;
  pwm: NumericalReading;
  timeToEmpty: NumericalReading;
  upperLevelSwitch: BooleanReading;
  waterLevel: NumericalReading;
};

const SumpDataContainer = styled.div`
  color: var(--black);
  padding: var(--windowPadding);
  padding-top: 0;
  width: var(--tierInspectionWindowWidth);
`;

const DatumContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const KeyFormatting = styled.div`
  float: left;
`;
const Key = props => (
  <KeyFormatting className={ClassNames.labelRegular}>
    {props.children}
  </KeyFormatting>
);

const ValueFormatting = styled.div`
  margin-left: auto;
  order: 2;
`;
const Value = props => (
  <ValueFormatting className={ClassNames.labelMedium}>
    {props.children}
  </ValueFormatting>
);

const TopSpacer = styled.div`
  width: auto;
  margin-top: var(--spacingSmall);
`;

const getStringValue = reading => {
  if (!reading || typeof reading.value === BuiltinType.UNDEFINED) {
    return 'Not found';
  }
  return reading.value;
};

const getBooleanValue = reading => {
  if (!reading || typeof reading.value === BuiltinType.UNDEFINED) {
    return 'Not found';
  }
  return typeof reading.value == BuiltinType.BOOLEAN
    ? reading.value
      ? 'True'
      : 'False'
    : 'Not found';
};
const getNumericalValue = (reading, decimals?) => {
  if (
    typeof reading === BuiltinType.UNDEFINED ||
    typeof reading.value === BuiltinType.UNDEFINED ||
    typeof reading.unit === BuiltinType.UNDEFINED
  ) {
    return 'Not found';
  }
  if (typeof decimals === BuiltinType.NUMBER) {
    return (
      reading.value.toFixed(decimals).toString() +
      ' ' +
      unitToString(reading.unit)
    );
  }
  return reading.value.toString() + ' ' + unitToString(reading.unit);
};

const getStatusValue = reading => {
  if (!reading) {
    return 'Not found';
  }
  return reading.value ? reading.value : reading.status; // TODO(philipp), ISSUE(1): Remove refs to status after migration
};

const SumpData = props => {
  return (
    <SumpDataContainer className={ClassNames.bodyRegular}>
      <TopSpacer />
      <DatumContainer>
        <Key>Pump Battery Level:</Key>
        <Value>{getNumericalValue(props.batteryLevelV, 0)}</Value>
      </DatumContainer>
      <DatumContainer>
        <Key>Battery Low from LED Connector:</Key>
        <Value>{getBooleanValue(props.batteryLevelLowFromLedConnector)}</Value>
      </DatumContainer>
      <DatumContainer>
        <Key>Battery Low From Relay:</Key>
        <Value>{getBooleanValue(props.batteryLevelLowFromRelay)}</Value>
      </DatumContainer>
      <InspectionDataDivider />
      <DatumContainer>
        <Key>Ingress Flow:</Key>
        <Value>{getNumericalValue(props.ingressFlowLM, 0)}</Value>
      </DatumContainer>
      <DatumContainer>
        <Key>{'Ingress Flow (10SecondAverage)'}:</Key>
        <Value>
          {getNumericalValue(props.ingressFlow10SecondAverageLM, 0)}
        </Value>
      </DatumContainer>
      <DatumContainer>
        <Key>Ingress Total Liters:</Key>
        <Value>
          {getNumericalValue(props.ingressFlowTotalLitersSinceReset, 0)}
        </Value>
      </DatumContainer>
      <InspectionDataDivider />
      <DatumContainer>
        <Key>Egress Flow:</Key>
        <Value>{getNumericalValue(props.egressFlowLM, 0)}</Value>
      </DatumContainer>
      <DatumContainer>
        <Key>{'Egress Flow (10 Second Average)'}:</Key>
        <Value>{getNumericalValue(props.egressFlow10SecondAverageLM, 0)}</Value>
      </DatumContainer>
      <DatumContainer>
        <Key>Egress Flow Total Liters:</Key>
        <Value>
          {getNumericalValue(props.egressFlowTotalLitersSinceReset, 0)}
        </Value>
      </DatumContainer>
      <InspectionDataDivider />
      <DatumContainer>
        <Key>Is Pump Running:</Key>
        <Value>{getBooleanValue(props.isPumpRunning)}</Value>
      </DatumContainer>
      <DatumContainer>
        <Key>Pump Electric Current:</Key>
        <Value>{getNumericalValue(props.pumpElectricCurrent, 0)}</Value>
      </DatumContainer>
      <DatumContainer>
        <Key>Pump Status:</Key>
        <Value>{getStatusValue(props.pumpStatus)}</Value>
      </DatumContainer>
      <InspectionDataDivider />
      <DatumContainer>
        <Key>Pump PWM:</Key>
        <Value>{getNumericalValue(props.pwm, 0)}</Value>
      </DatumContainer>
      <DatumContainer>
        <Key>Max Pump PWM:</Key>
        <Value>{getNumericalValue(props.maxPwm, 0)}</Value>
      </DatumContainer>
      <DatumContainer>
        <Key>Override Engaged:</Key>
        <Value>{getBooleanValue(props.override)}</Value>
      </DatumContainer>
      <DatumContainer>
        <Key>Accumulation Time:</Key>
        <Value>{getNumericalValue(props.accumulationTimeS, 0)}</Value>
      </DatumContainer>
      <DatumContainer>
        <Key>Approx. Time Until Empty:</Key>
        <Value>{getNumericalValue(props.timeToEmpty, 0)}</Value>
      </DatumContainer>
      <DatumContainer>
        <Key>Upper Level Switch Engaged:</Key>
        <Value>{getBooleanValue(props.upperLevelSwitch)}</Value>
      </DatumContainer>
    </SumpDataContainer>
  );
};

export default SumpData;
