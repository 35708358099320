import { ServerToFarmType } from 'model/src/dataflowprotocol/servertofarm/ServerToFarmType';
import React from 'react';

import ContextButton from './button/ContextButton';
import SubMenuButton from './button/SubMenuButton';

enum SchedulerStackSubCategories {
  SCHEDULE_STACK_DRAIN = 'SCHEDULE_STACK_DRAIN',
  SCHEDULE_STACK_PUMP = 'SCHEDULE_STACK_PUMP',
  SCHEDULE_STACK_LIGHTS = 'SCHEDULE_STACK_LIGHTS',
}

enum SchedulerTierSubCategories {
  SCHEDULE_TIER_AC = 'SCHEDULE_TIER_AC',
  SCHEDULE_TIER_DOOR = 'SCHEDULE_TIER_DOOR',
  SCHEDULE_TIER_FANS = 'SCHEDULE_TIER_FANS',
  SCHEDULE_TIER_GANTRY = 'SCHEDULE_TIER_GANTRY',
  SCHEDULE_TIER_LIGHTS = 'SCHEDULE_TIER_LIGHTS',
  SCHEDULE_TIER_RESET_FLOW = 'SCHEDULE_TIER_RESET_FLOW',
  SCHEDULE_TIER_VALVE = 'SCHEDULE_TIER_VALVE',
}

enum SchedulerTowerSubCategories {
  SCHEDULE_TOWER_AC = 'SCHEDULE_TOWER_AC',
  SCHEDULE_TOWER_DOORS = 'SCHEDULE_TOWER_DOORS',
  SCHEDULE_TOWER_DOCKING = 'SCHEDULE_TOWER_DOCKING',
  SCHEDULE_TOWER_FANS = 'SCHEDULE_TOWER_FANS',
  SCHEDULE_TOWER_LIGHTS = 'SCHEDULE_TOWER_LIGHTS',
  SCHEDULE_TOWER_PUMP = 'SCHEDULE_TOWER_PUMP',
  SCHEDULE_TOWER_RESET_FLOW = 'SCHEDULE_TOWER_RESET_FLOW',
  SCHEDULE_TOWER_VALVE = 'SCHEDULE_TOWER_VALVE',
}

const SchedulerWindowAddToScheduleContextMenu = props => {
  const isStackSchedule =
    !!props.windowClickInfo &&
    props.windowClickInfo.selectedStack &&
    props.windowClickInfo.selectedStack !== '';
  const isTierSchedule =
    !!props.windowClickInfo &&
    props.windowClickInfo.selectedTier &&
    props.windowClickInfo.selectedTier !== '';

  const isTowerSchedule =
    !!props.windowClickInfo &&
    props.windowClickInfo.selectedTower &&
    props.windowClickInfo.selectedTower !== '' &&
    !isTierSchedule;

  const openModal =
    props.windowClickInfo && props.windowClickInfo.openModal
      ? props.windowClickInfo.openModal
      : commandType => {};
  return (
    <>
      {isStackSchedule && (
        <>
          <SubMenuButton
            {...props}
            id={SchedulerStackSubCategories.SCHEDULE_STACK_DRAIN}
            openSubMenu={props.openSubMenu}
            buttonText={'Drain'}
            setOpenSubMenu={props.setOpenSubMenu}>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.DrainOpenCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Drain Open
            </ContextButton>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.DrainCloseCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Drain Close
            </ContextButton>
          </SubMenuButton>
          <SubMenuButton
            {...props}
            id={SchedulerStackSubCategories.SCHEDULE_STACK_LIGHTS}
            openSubMenu={props.openSubMenu}
            buttonText={'Lights'}
            setOpenSubMenu={props.setOpenSubMenu}>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.LightsOnCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Lights On
            </ContextButton>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.LightsOffCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Lights Off
            </ContextButton>
          </SubMenuButton>
          <SubMenuButton
            {...props}
            id={SchedulerStackSubCategories.SCHEDULE_STACK_PUMP}
            openSubMenu={props.openSubMenu}
            buttonText={'Pump'}
            setOpenSubMenu={props.setOpenSubMenu}>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.PumpOnCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Pump On
            </ContextButton>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.PumpOffCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Pump Off
            </ContextButton>
          </SubMenuButton>
        </>
      )}
      {isTierSchedule && (
        <>
          <SubMenuButton
            {...props}
            id={SchedulerTierSubCategories.SCHEDULE_TIER_AC}
            openSubMenu={props.openSubMenu}
            buttonText={'AC'}
            setOpenSubMenu={props.setOpenSubMenu}>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.AcOnCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Ac On
            </ContextButton>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.AcOffCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Ac Off
            </ContextButton>
          </SubMenuButton>
          <ContextButton
            onClick={() => {
              openModal(ServerToFarmType.CloseConnectionRequest);
            }}
            setOpenSubMenu={props.setOpenSubMenu}>
            Close Connection
          </ContextButton>
          <SubMenuButton
            {...props}
            id={SchedulerTierSubCategories.SCHEDULE_TIER_DOOR}
            openSubMenu={props.openSubMenu}
            buttonText={'Door'}
            setOpenSubMenu={props.setOpenSubMenu}>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.DoorOpenCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Door Open{' '}
            </ContextButton>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.DoorCloseCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Door Close
            </ContextButton>
          </SubMenuButton>
          <SubMenuButton
            {...props}
            id={SchedulerTierSubCategories.SCHEDULE_TIER_FANS}
            openSubMenu={props.openSubMenu}
            buttonText={'Fans'}
            setOpenSubMenu={props.setOpenSubMenu}>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.FansOnCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Fans On
            </ContextButton>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.FansOffCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Fans Off
            </ContextButton>
          </SubMenuButton>
          <SubMenuButton
            {...props}
            id={SchedulerTierSubCategories.SCHEDULE_TIER_GANTRY}
            openSubMenu={props.openSubMenu}
            buttonText={'Gantry'}
            setOpenSubMenu={props.setOpenSubMenu}>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.GantryFindFarXCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Gantry Find Far X
            </ContextButton>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.GantryFindFarYCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Gantry Find Far Y
            </ContextButton>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.GantryFindFarZCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Gantry Find Far Z
            </ContextButton>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.GantryGoToCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Gantry Go To
            </ContextButton>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.GantryHardStopCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Gantry Hard Stop
            </ContextButton>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.GantryHomeCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Gantry Home
            </ContextButton>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.GantryMoveByCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Gantry Move By
            </ContextButton>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.GantryStopCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Gantry Stop
            </ContextButton>
          </SubMenuButton>
          <ContextButton
            onClick={() => {
              openModal(ServerToFarmType.InitializeCommand);
            }}
            setOpenSubMenu={props.setOpenSubMenu}>
            Initialize
          </ContextButton>
          <SubMenuButton
            {...props}
            id={SchedulerTierSubCategories.SCHEDULE_TIER_LIGHTS}
            openSubMenu={props.openSubMenu}
            buttonText={'Lights'}
            setOpenSubMenu={props.setOpenSubMenu}>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.LightsOnCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Lights On
            </ContextButton>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.LightsOffCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Lights Off
            </ContextButton>
          </SubMenuButton>
          <ContextButton
            onClick={() => {
              openModal(ServerToFarmType.MessageRequest);
            }}
            setOpenSubMenu={props.setOpenSubMenu}>
            Message Request
          </ContextButton>
          <ContextButton
            onClick={() => {
              openModal(ServerToFarmType.RebootCommand);
            }}
            setOpenSubMenu={props.setOpenSubMenu}>
            Reboot
          </ContextButton>
          <SubMenuButton
            {...props}
            id={SchedulerTierSubCategories.SCHEDULE_TIER_RESET_FLOW}
            openSubMenu={props.openSubMenu}
            buttonText={'Reset Flow'}
            setOpenSubMenu={props.setOpenSubMenu}>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.ResetFlowInCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Reset Flow In
            </ContextButton>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.ResetFlowOutCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Reset Flow Out
            </ContextButton>
          </SubMenuButton>
          <ContextButton
            onClick={() => {
              openModal(ServerToFarmType.ShutDownCommand);
            }}
            setOpenSubMenu={props.setOpenSubMenu}>
            Shut Down
          </ContextButton>
          <ContextButton
            onClick={() => {
              openModal(ServerToFarmType.UpdateFirmwareCommand);
            }}
            setOpenSubMenu={props.setOpenSubMenu}>
            Update Firmware
          </ContextButton>
          <SubMenuButton
            {...props}
            id={SchedulerTierSubCategories.SCHEDULE_TIER_VALVE}
            openSubMenu={props.openSubMenu}
            buttonText={'Valve'}
            setOpenSubMenu={props.setOpenSubMenu}>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.ValveOpenCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Valve Open
            </ContextButton>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.ValveCloseCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Valve Close
            </ContextButton>
          </SubMenuButton>
        </>
      )}
      {isTowerSchedule && (
        <>
          <SubMenuButton
            {...props}
            id={SchedulerTowerSubCategories.SCHEDULE_TOWER_AC}
            openSubMenu={props.openSubMenu}
            buttonText={'AC'}
            setOpenSubMenu={props.setOpenSubMenu}>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.AcOnCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Ac On
            </ContextButton>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.AcOffCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Ac Off
            </ContextButton>
          </SubMenuButton>
          <ContextButton
            onClick={() => {
              openModal(ServerToFarmType.ChangeLightLevelCommand);
            }}
            setOpenSubMenu={props.setOpenSubMenu}>
            Change Light Level
          </ContextButton>
          <ContextButton
            onClick={() => {
              openModal(ServerToFarmType.CloseConnectionRequest);
            }}
            setOpenSubMenu={props.setOpenSubMenu}>
            Send Close Connection Request
          </ContextButton>
          <SubMenuButton
            {...props}
            id={SchedulerTowerSubCategories.SCHEDULE_TOWER_DOORS}
            openSubMenu={props.openSubMenu}
            buttonText={'Doors'}
            setOpenSubMenu={props.setOpenSubMenu}>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.DoorOpenCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Door Open
            </ContextButton>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.DoorCloseCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Door Close
            </ContextButton>
          </SubMenuButton>
          <SubMenuButton
            {...props}
            id={SchedulerTowerSubCategories.SCHEDULE_TOWER_DOCKING}
            openSubMenu={props.openSubMenu}
            buttonText={'Docking'}
            setOpenSubMenu={props.setOpenSubMenu}>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.DockCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Dock
            </ContextButton>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.UndockCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Undock
            </ContextButton>
          </SubMenuButton>
          <SubMenuButton
            {...props}
            id={SchedulerTowerSubCategories.SCHEDULE_TOWER_FANS}
            openSubMenu={props.openSubMenu}
            buttonText={'Fans'}
            setOpenSubMenu={props.setOpenSubMenu}>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.FansOnCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Fans On
            </ContextButton>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.FansOffCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Fans Off
            </ContextButton>
          </SubMenuButton>
          <SubMenuButton
            {...props}
            id={SchedulerTowerSubCategories.SCHEDULE_TOWER_LIGHTS}
            openSubMenu={props.openSubMenu}
            buttonText={'Lights'}
            setOpenSubMenu={props.setOpenSubMenu}>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.LightsOnCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Lights On
            </ContextButton>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.LightsOffCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Lights Off
            </ContextButton>
          </SubMenuButton>
          <SubMenuButton
            {...props}
            id={SchedulerTowerSubCategories.SCHEDULE_TOWER_PUMP}
            openSubMenu={props.openSubMenu}
            buttonText={'Pump'}
            setOpenSubMenu={props.setOpenSubMenu}>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.PumpOnCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Pump On
            </ContextButton>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.PumpOffCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Pump Off
            </ContextButton>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.PumpAutoCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Pump Auto
            </ContextButton>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.PumpToEmptyCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Pump To Empty
            </ContextButton>
          </SubMenuButton>
          <ContextButton
            onClick={() => {
              openModal(ServerToFarmType.RebootCommand);
            }}
            setOpenSubMenu={props.setOpenSubMenu}>
            Reboot
          </ContextButton>
          <SubMenuButton
            {...props}
            id={SchedulerTowerSubCategories.SCHEDULE_TOWER_RESET_FLOW}
            openSubMenu={props.openSubMenu}
            buttonText={'Reset Flow'}
            setOpenSubMenu={props.setOpenSubMenu}>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.ResetFlowInCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Reset Flow In
            </ContextButton>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.ResetFlowOutCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Reset Flow Out
            </ContextButton>
          </SubMenuButton>
          <ContextButton
            onClick={() => {
              openModal(ServerToFarmType.ShutDownCommand);
            }}
            setOpenSubMenu={props.setOpenSubMenu}>
            Shut Down
          </ContextButton>
          <ContextButton
            onClick={() => {
              openModal(ServerToFarmType.UmbilicalGantryHomeCommand);
            }}
            setOpenSubMenu={props.setOpenSubMenu}>
            Umbilical Gantry Home
          </ContextButton>
          <ContextButton
            onClick={() => {
              openModal(ServerToFarmType.UpdateFirmwareCommand);
            }}
            setOpenSubMenu={props.setOpenSubMenu}>
            Update Firmware
          </ContextButton>
          <SubMenuButton
            {...props}
            id={SchedulerTowerSubCategories.SCHEDULE_TOWER_VALVE}
            openSubMenu={props.openSubMenu}
            buttonText={'Valve'}
            setOpenSubMenu={props.setOpenSubMenu}>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.ValveOpenCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Valve Open
            </ContextButton>
            <ContextButton
              onClick={() => {
                openModal(ServerToFarmType.ValveCloseCommand);
              }}
              setOpenSubMenu={props.setOpenSubMenu}>
              Valve Close
            </ContextButton>
          </SubMenuButton>
        </>
      )}
    </>
  );
};

export default SchedulerWindowAddToScheduleContextMenu;
