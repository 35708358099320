"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convert = exports.unitToString = exports.Unit = void 0;
var Unit;

(function (Unit) {
  Unit["AMPS"] = "AMPS";
  Unit["CM"] = "CM";
  Unit["DEGREES_ANGLE"] = "DEGREES_ANGLE";
  Unit["DEGREES_C"] = "DEGREES_C";
  Unit["DEGREES_F"] = "DEGREES_F";
  Unit["HERTZ"] = "HERTZ";
  Unit["KPa"] = "KPa";
  Unit["LITERS"] = "LITERS";
  Unit["LITERS_PER_MINUTE"] = "LITERS_PER_MINUTE";
  Unit["LITERS_PER_SECOND"] = "LITERS_PER_SECOND";
  Unit["MILLISECONDS"] = "MILLISECONDS";
  Unit["MILLITESLAS"] = "MT";
  Unit["MINUTES"] = "MINUTES";
  Unit["MM"] = "MM";
  Unit["MM_PER_SECOND"] = "MM_PER_SECOND";
  Unit["NANOSECONDS"] = "NANOSECONDS";
  Unit["NONE"] = "NONE";
  Unit["PERCENT"] = "PERCENT";
  Unit["RPM"] = "RPM";
  Unit["RADIANS_PER_SECOND"] = "RADIANS_PER_SECOND";
  Unit["SECONDS"] = "SECONDS";
  Unit["UM"] = "UM";
  Unit["UNITLESS"] = "UNITLESS";
  Unit["VOLTS"] = "VOLTS";
})(Unit = exports.Unit || (exports.Unit = {}));

var unitToString = function unitToString(unit) {
  switch (unit) {
    case Unit.AMPS:
      return 'A';

    case Unit.CM:
      return 'cm';

    case Unit.DEGREES_ANGLE:
      return '°';

    case Unit.DEGREES_C:
      return '°C';

    case Unit.DEGREES_F:
      return '°F';

    case Unit.HERTZ:
      return 'Hz';

    case Unit.KPa:
      return 'KPa';

    case Unit.LITERS:
      return 'L';

    case Unit.LITERS_PER_MINUTE:
      return 'L/m';

    case Unit.LITERS_PER_SECOND:
      return 'L/s';

    case Unit.MILLISECONDS:
      return 'ms';

    case Unit.MINUTES:
      return 'min';

    case Unit.MM:
      return 'mm';

    case Unit.NONE:
      return '';

    case Unit.PERCENT:
      return '%';

    case Unit.RADIANS_PER_SECOND:
      return 'rad/s';

    case Unit.RPM:
      return 'rpm';

    case Unit.SECONDS:
      return 's';

    case Unit.UM:
      return 'µm';

    case Unit.UNITLESS:
      return '';

    case Unit.VOLTS:
      return 'V';
  }
};

exports.unitToString = unitToString;

var convert = function convert(from, to) {
  switch (from) {
    case Unit.CM:
      {
        switch (to) {
          case Unit.MM:
            {
              return 10;
            }

          default:
            {
              console.error('NumericalReading::convert::Unknown conversion from ' + from + ' to ' + to);
            }
            break;
        }

        break;
      }

    case Unit.MM:
      {
        switch (to) {
          case Unit.CM:
            {
              return 1.0 / 10.0;
            }

          case Unit.UM:
            {
              return 1000;
            }

          default:
            {
              console.error('NumericalReading::convert::Unknown conversion from ' + from + ' to ' + to);
            }
            break;
        }

        break;
      }

    case Unit.MILLISECONDS:
      {
        switch (to) {
          case Unit.SECONDS:
            {
              return 1.0 / 1000.0;
            }

          case Unit.NANOSECONDS:
            {
              return 1e6;
            }

          default:
            {
              console.error('NumericalReading::convert::Unknown conversion from ' + from + ' to ' + to);
            }
        }

        break;
      }

    case Unit.SECONDS:
      {
        switch (to) {
          case Unit.MILLISECONDS:
            {
              return 1000;
            }

          case Unit.NANOSECONDS:
            {
              return 1e9;
            }
        }

        break;
      }

    case Unit.MINUTES:
      {
        switch (to) {
          case Unit.SECONDS:
            {
              return 60;
            }

          case Unit.MILLISECONDS:
            {
              return 6e4;
            }
        }

        break;
      }

    case Unit.UM:
      {
        switch (to) {
          case Unit.MM:
            {
              return 1.0 / 1000.0;
            }

          default:
            {
              console.error('NumericalReading::convert::Unknown conversion from ' + from + ' to ' + to);
              break;
            }
        }

        break;
      }

    default:
      {
        console.error('NumericalReading::convert::Unknown conversion from ' + from + ' to ' + to);
        break;
      }
  }
};

exports.convert = convert;