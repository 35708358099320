import { ServerToFarmType } from 'model/src/dataflowprotocol/servertofarm/ServerToFarmType';
import TimerTemplate, {
  Period,
  TimerTemplateUpdateType,
} from 'model/src/timer/TimerTemplate';
import React, { useContext } from 'react';
import Clock, {
  MILLISECONDS_PER_DAY,
  MILLISECONDS_PER_MINUTE,
  MINUTES_PER_DAY,
} from 'services/src/common/Clock';
import { convertUtcToLocalMinutes } from 'services/src/timezone/TimezoneUtility';
import styled from 'styled-components';

import { cssVar } from '../..';
import { getModalFields } from '../../farm-ui/windows/SchedulerWindow';
import InjectionContext, {
  InjectionContextType,
} from '../../injection/InjectionContext';
import WindowType from '../window/WindowTypes';
import ScheduleTimerTemplateFormatter from './ScheduleTimerTemplateFormatter';

const SVG_ASSUMED_SCALE = 8;
const MINUTES_PER_HOUR = 60;
const MAX_DAY_CLICK_VALUE = MINUTES_PER_DAY - 15;
const CLICK_Y_OFFSET = 12;

const Schedule = styled.div`
  position: relative;
  width: calc(var(--schedulerWindowWidth) - var(--spacingExtraLarge));
  var(--scheduleCalendarBackgroundHeight);
  display: flex;
  flex-direction: column;
  margin: var(--spacingMedium);
  gap: calc(
    var(--spacingMedium) - calc(var(--spacingMicro) + var(--spacingMini))
  );
  z-index: 100;
`;

const HourBlockStyling = styled.div`
  display: flex;
  align-items: center;
  font-size: calc(var(--spacingSmall) + var(--spacingMini));
`;

const Hour = styled.div`
  margin-right: 10px;
  color: var(--scheduleHourColor);
`;

const Divider = styled.div`
  margin: 0;
  padding: 0;
  flex-grow: 1;
  height: 1px;
  background-color: var(--dividerColor);
`;

const HourBlock = props => {
  const hourString =
    props.hour < 10 ? '0' + props.hour.toString() : props.hour.toString();

  return (
    <HourBlockStyling>
      <Hour>{`${hourString}:00`}</Hour>
      <Divider />
    </HourBlockStyling>
  );
};

const CurrentTimeIndicatorContainer = styled.div`
  display: block;
  position: absolute;
  z-index: 102;
  right: 0px;
  padding: 0;
  height: var(--spacingMedium);
  width: calc(var(--schedulerCurrentTimeIndicatorWidth));
  text-align: left;
  top: ${props => props.top};
`;

const CurrentTimeIndicatorBar = styled.div`
  position: absolute;
  z-index: 102;
  height: 1px;
  width: var(--schedulerCurrentTimeIndicatorWidth);
  right: 0px;
  top: 11px;
  margin: 0;
  padding: 0;
  background-color: var(--schedulerCurrentTimeIndicatorColor);
`;

const IndicatorBall = props => (
  <svg
    style={{ order: 2, float: 'left', marginTop: '7px' }}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size}
    height={props.size}
    viewBox={'0 0 ' + props.size.toString() + ' ' + props.size.toString()}
    fill="none">
    <ellipse
      cx={3.81188 * props.scale}
      cy={4 * props.scale}
      rx={3.81188 * props.scale}
      ry={4 * props.scale}
      fill={cssVar('--schedulerCurrentTimeIndicatorColor')}
    />
  </svg>
);

const Schedule24HourBackground = props => {
  const targetUrl =
    props.selectedTier && props.selectedTier !== ''
      ? props.selectedTier
      : props.selectedStack && props.selectedStack !== ''
      ? props.selectedStack
      : props.selectedTower && props.selectedTower !== ''
      ? props.selectedTower
      : props.selectedTug && props.selectedTug && props.selectedTug !== ''
      ? props.selectedTug
      : null;
  const contextMenuIsEnabled = () => {
    return !!targetUrl;
  };
  const injectionContext: InjectionContextType = useContext(InjectionContext);
  const clock = injectionContext.clockFactory() as Clock;
  const openContextMenu = clickEvent => {
    const { pageY } = clickEvent;
    const { top } = clickEvent.currentTarget.getBoundingClientRect();
    const relativeY = pageY - top - window.scrollY - CLICK_Y_OFFSET;

    const pixelsFromMidnight = relativeY;
    const timeInMinutes = getTimeFromPixels(pixelsFromMidnight);
    const timeToSchedule = roundToNearest15Minutes(keepInBounds(timeInMinutes));
    props.showContextMenu('Add to Schedule', {
      windowType: WindowType.SCHEDULER,
      timerTemplateTime: timeToSchedule,
      selectedStack: props.selectedStack,
      selectedTier: props.selectedTier,
      selectedTower: props.selectedTower,
      selectedTug: props.selectedTug,
      openModal: command => {
        props.hideContextMenu();
        openNewAddTimerTemplateModal(command, timeToSchedule, targetUrl);
      },
    });
  };

  const keepInBounds = (minutes: number) => {
    if (minutes <= 0) {
      return 0;
    } else if (minutes > MAX_DAY_CLICK_VALUE) {
      return MAX_DAY_CLICK_VALUE;
    } else {
      return minutes;
    }
  };

  const roundToNearest15Minutes = (minutes: number) => {
    const roundedTo15 = Math.round(minutes / 15) * 15;
    return roundedTo15;
  };

  const openNewAddTimerTemplateModal = (
    commandType: ServerToFarmType,
    timeInMinutes: number,
    targetId: string
  ) => {
    const commandModalFields = getModalFields(commandType, targetId) as any;

    const hours = Math.floor(timeInMinutes / MINUTES_PER_HOUR);
    const minutes = timeInMinutes % MINUTES_PER_HOUR;
    const time = { hours, minutes };

    props.setModal({
      commandType: commandType,
      updateType: TimerTemplateUpdateType.ADD,
      time: time,
      commandFields: commandModalFields,
    });
  };

  const HOUR_TO_PIXEL_MULTIPLIER = Number(
    cssVar('--spacingExtraLarge').replace('px', '')
  );
  const MINUTE_TO_PIXEL_MULTIPLIER =
    Number(cssVar('--spacingExtraLarge').replace('px', '')) / 60;

  const hours = Array.from({ length: 24 }, (_value, index) => index);

  const getTimeInPixels = time => {
    const currentHour = time.hours;
    const currentMinute = time.minutes;
    return (
      (
        currentHour * HOUR_TO_PIXEL_MULTIPLIER +
        currentMinute * MINUTE_TO_PIXEL_MULTIPLIER
      ).toString() + 'px'
    );
  };

  const getTimeFromPixels = (pixels: number) => {
    const totalMinutes = pixels / MINUTE_TO_PIXEL_MULTIPLIER;
    return totalMinutes;
  };

  const currentTimeInPixels = getTimeInPixels({
    hours: props.currentTime.getHours(),
    minutes: props.currentTime.getMinutes(),
  });

  const ballSize = Number(cssVar('--spacingSmall').replace('px', ''));
  const ballScale = ballSize / SVG_ASSUMED_SCALE;

  const formatDailyTimerTemplates = (timerTemplates: TimerTemplate[]) => {
    const dailyTimerTemplates = timerTemplates.filter(timerTemplate => {
      return (
        timerTemplate.period === Period.DAY &&
        timerTemplate.periodOffset_ms < MILLISECONDS_PER_DAY
      );
    });

    const convertToUtcSeconds = timeInSeconds => {
      let convertedTime = convertUtcToLocalMinutes(
        timeInSeconds,
        clock.getUtcOffsetMinutes()
      );
      if (convertedTime < 0) {
        convertedTime = MINUTES_PER_DAY + convertedTime;
      }

      return convertedTime;
    };

    const dailyTimerTemplateDisplays = dailyTimerTemplates.map(
      timerTemplate => {
        return {
          timerTemplate: timerTemplate,
          actionType: timerTemplate.actionType,
          id: timerTemplate.id,
          timeInMinutes: convertToUtcSeconds(
            timerTemplate.periodOffset_ms / MILLISECONDS_PER_MINUTE
          ),
        };
      }
    );

    return dailyTimerTemplateDisplays;
  };

  const formatWeeklyTimerTemplates = (timerTemplates: TimerTemplate[]) => {
    const weeklyTimerTemplates = timerTemplates.filter(
      timerTemplate => timerTemplate.period === Period.WEEK
    ); //TODO(austin): implement
  };

  const formatMonthlyTimerTemplates = (timerTemplates: TimerTemplate[]) => {
    const monthlyTimerTemplates = timerTemplates.filter(
      timerTemplate => timerTemplate.period === Period.MONTH
    ); //TODO(austin): implement
  };

  const formatYearlyTimerTemplates = (timerTemplates: TimerTemplate[]) => {
    const yearlyTimerTemplates = timerTemplates.filter(
      timerTemplate => timerTemplate.period === Period.YEAR
    ); //TODO(austin): implement
  };
  const currentDailyTimerTemplates = formatDailyTimerTemplates(
    props.getCurrentTimerTemplates(
      targetUrl,
      Array.from(props.timerTemplateMap.values())
    )
  );

  return (
    <Schedule
      onClick={() => {
        props.hideContextMenu();
        props.onClick();
      }}
      onContextMenu={e => {
        e.preventDefault();
        if (contextMenuIsEnabled()) {
          openContextMenu(e);
        }
      }}>
      <ScheduleTimerTemplateFormatter
        target={targetUrl}
        currentDailyTimerTemplates={currentDailyTimerTemplates}
        showContextMenu={props.showContextMenu}
        hideContextMenu={props.hideContextMenu}
        setModal={props.setModal}
      />
      {hours.map(hour => (
        <HourBlock key={hour} hour={hour} />
      ))}
      <CurrentTimeIndicatorContainer top={currentTimeInPixels}>
        <IndicatorBall size={ballSize} scale={ballScale} />
        <CurrentTimeIndicatorBar />
      </CurrentTimeIndicatorContainer>
    </Schedule>
  );
};

export default Schedule24HourBackground;
