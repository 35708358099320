import { Data } from 'model/src/dataflowprotocol/Datum';
import { BooleanReading } from 'model/src/series/BooleanReading';
import {
  NumericalReading,
  unitToString,
} from 'model/src/series/NumericalReading';
import { StringReading } from 'model/src/series/StringReading';
import { ValveStateReading } from 'model/src/series/ValveStateReading';
import { BuiltinType } from 'model/src/typescript/Typescript';
import React from 'react';
import styled from 'styled-components';

import ClassNames from '../../ClassNames';
import InspectionDataDivider from '../dividers/InspectionDataDivider';

export type TierDataProps = {
  acState: Data<StringReading>;
  airTemperature: Data<NumericalReading>;
  doorPosition: Data<NumericalReading>;
  egressFlow: Data<NumericalReading>;
  egressTotalLiters: Data<NumericalReading>;
  fan1Pwm: Data<NumericalReading>;
  fan1Rpm: Data<NumericalReading>;
  fan1State: Data<StringReading>;
  fan2Pwm: Data<NumericalReading>;
  fan2Rpm: Data<NumericalReading>;
  fan2State: Data<StringReading>;
  gantryPositionX: Data<NumericalReading>;
  gantryPositionY: Data<NumericalReading>;
  gantryPositionZ: Data<NumericalReading>;
  heaterValveState: Data<ValveStateReading>;
  ingressFlow: Data<NumericalReading>;
  ingressTotalLiters: Data<NumericalReading>;
  isDoorClosed: Data<BooleanReading>;
  isDoorOpen: Data<BooleanReading>;
  lights: Data<StringReading>;
  pumpPwm: Data<NumericalReading>;
  pumpStatus: Data<StringReading>;
  overflowFlow: Data<NumericalReading>;
  relativeHumidity: Data<NumericalReading>;
  valveState: Data<ValveStateReading>;
  waterPresent1: Data<BooleanReading>;
  waterPresent2: Data<BooleanReading>;
  waterTemperature1: Data<NumericalReading>;
  waterTemperature2: Data<NumericalReading>;
};

const TierDataContainer = styled.div`
  color: var(--black);
  padding: var(--windowPadding);
  padding-top: 0;
  width: var(--tierInspectionWindowWidth);
`;

const KeyFormatting = styled.div`
  float: left;
`;
const Key = props => (
  <KeyFormatting className={ClassNames.labelRegular}>
    {props.children}
  </KeyFormatting>
);

const ValueFormatting = styled.div`
  margin-left: auto;
  order: 2;
`;
const Value = props => (
  <ValueFormatting className={ClassNames.labelMedium}>
    {props.children}
  </ValueFormatting>
);

const DatumContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const FlexBarKey = props => (
  <div className={ClassNames.labelRegular}>{props.children}</div>
);

const FlexBarValue = props => (
  <div style={{ width: '25%' }} className={ClassNames.labelMedium}>
    {props.children}
  </div>
);

const getStringValue = stream => {
  if (
    !stream.data[0] ||
    !stream.data[0].reading ||
    !stream.data[0].reading.value
  ) {
    return 'Not found';
  }
  return stream.data[0].reading.value;
};
const getBoolean = stream => {
  if (
    !stream.data[0] ||
    !stream.data[0].reading ||
    typeof stream.data[0].reading.value === BuiltinType.UNDEFINED
  ) {
    return undefined;
  }
  return typeof stream.data[0].reading.value == BuiltinType.BOOLEAN
    ? stream.data[0].reading.value
    : undefined;
};
const getBooleanValue = stream => {
  if (
    !stream.data[0] ||
    !stream.data[0].reading ||
    stream.data[0].reading.value === BuiltinType.UNDEFINED
  ) {
    return 'Not found';
  }
  return typeof stream.data[0].reading.value == BuiltinType.BOOLEAN
    ? stream.data[0].reading.value
      ? 'True'
      : 'False'
    : 'Not found';
};
const getNumericalValue = (stream, decimals?: number) => {
  if (
    typeof stream.data[0] === BuiltinType.UNDEFINED ||
    typeof !stream.data[0].reading === BuiltinType.UNDEFINED ||
    typeof stream.data[0].reading.value === BuiltinType.UNDEFINED
  ) {
    return 'Not found';
  }

  if (
    typeof stream.data[0].reading.value !== BuiltinType.NUMBER &&
    !stream.data[0].reading.value
  ) {
    return 'Bad value';
  }

  if (typeof decimals === BuiltinType.NUMBER) {
    return (
      stream.data[0].reading.value.toFixed(decimals).toString() +
      ' ' +
      unitToString(stream.data[0].reading.unit)
    );
  }

  return (
    stream.data[0].reading.value.toString() +
    ' ' +
    unitToString(stream.data[0].reading.unit)
  );
};

const getStatusValue = stream => {
  if (!stream.data[0] || !stream.data[0].reading) {
    return 'Not found';
  }
  return stream.data[0].reading.value
    ? stream.data[0].reading.value
    : stream.data[0].reading.status; // TODO(philipp), ISSUE(1): Remove refs to status after migration
};

const getValveStateValue = stream => {
  if (!stream.data[0] || !stream.data[0].reading) {
    return 'Not found';
  }
  return stream.data[0].reading.value
    ? stream.data[0].reading.value
    : stream.data[0].reading.valveState; // TODO(philipp), ISSUE(1): Remove refs to status after migration
};

const TierData = (props: TierDataProps) => {
  return (
    <TierDataContainer className={ClassNames.bodyRegular}>
      <DatumContainer>
        <Key>AC:</Key> <Value>{getStringValue(props.acState)}</Value>
      </DatumContainer>
      <InspectionDataDivider />
      <DatumContainer>
        <Key>Lights:</Key>
        <Value>{getStringValue(props.lights)}</Value>
      </DatumContainer>
      <InspectionDataDivider />
      <DatumContainer>
        <Key>Ingress Flow:</Key>{' '}
        <Value>{getNumericalValue(props.ingressFlow)}</Value>
      </DatumContainer>
      <DatumContainer>
        <Key>Ingress Total Liters:</Key>{' '}
        <Value>{getNumericalValue(props.ingressTotalLiters)}</Value>
      </DatumContainer>
      <InspectionDataDivider />
      <DatumContainer>
        <Key>Egress Flow:</Key>{' '}
        <Value>{getNumericalValue(props.egressFlow)}</Value>
      </DatumContainer>
      <DatumContainer>
        <Key>Egress Total Liters:</Key>{' '}
        <Value>{getNumericalValue(props.egressTotalLiters)}</Value>
      </DatumContainer>
      <InspectionDataDivider />
      <DatumContainer>
        <Key>Overflow Flow:</Key>{' '}
        <Value>{getNumericalValue(props.overflowFlow)}</Value>
      </DatumContainer>
      <InspectionDataDivider />
      <DatumContainer>
        <Key>Air Temperature:</Key>{' '}
        <Value>{getNumericalValue(props.airTemperature)}</Value>
      </DatumContainer>
      <DatumContainer>
        <Key>Relative Humidity:</Key>{' '}
        <Value>{getNumericalValue(props.relativeHumidity)}</Value>
      </DatumContainer>
      <DatumContainer>
        <Key>Heater Valve State:</Key>{' '}
        <Value>{getValveStateValue(props.heaterValveState)}</Value>
      </DatumContainer>
      <InspectionDataDivider />
      <DatumContainer>
        <Key>Valve State:</Key>{' '}
        <Value>{getValveStateValue(props.valveState)}</Value>
      </DatumContainer>
      <InspectionDataDivider />
      <DatumContainer>
        <Key>Pump Status:</Key>{' '}
        <Value>{getStatusValue(props.pumpStatus)}</Value>
      </DatumContainer>
      <DatumContainer>
        <Key>Pump PWM:</Key> <Value>{getNumericalValue(props.pumpPwm)}</Value>
      </DatumContainer>
      <InspectionDataDivider />
      <DatumContainer>
        <Key>Water Present 1:</Key>{' '}
        <Value>{getBooleanValue(props.waterPresent1)}</Value>
      </DatumContainer>
      <DatumContainer>
        <Key>Water Present 2:</Key>{' '}
        <Value>{getBooleanValue(props.waterPresent2)}</Value>
      </DatumContainer>
      <DatumContainer>
        <Key>Water Temperature 1:</Key>{' '}
        <Value>{getNumericalValue(props.waterTemperature1)}</Value>
      </DatumContainer>
      <DatumContainer>
        <Key>Water Temperature 2:</Key>
        <Value>{getNumericalValue(props.waterTemperature2)}</Value>
      </DatumContainer>
      <InspectionDataDivider />
      <DatumContainer>
        <Key>Fan 1 State:</Key>
        <Value>{getStringValue(props.fan1State)}</Value>
      </DatumContainer>
      <DatumContainer>
        <Key>Fan 1 PWM:</Key> <Value>{getNumericalValue(props.fan1Pwm)}</Value>
      </DatumContainer>
      <DatumContainer>
        <Key>Fan 1 RPM:</Key> <Value>{getNumericalValue(props.fan1Rpm)}</Value>
      </DatumContainer>
      <InspectionDataDivider />
      <DatumContainer>
        <Key>Fan 2 State:</Key> <Value>{getStringValue(props.fan2State)}</Value>
      </DatumContainer>
      <DatumContainer>
        <Key>Fan 2 PWM:</Key> <Value>{getNumericalValue(props.fan2Pwm)}</Value>
      </DatumContainer>
      <DatumContainer>
        <Key>Fan 2 RPM:</Key> <Value>{getNumericalValue(props.fan2Rpm)}</Value>
      </DatumContainer>
      <InspectionDataDivider />
      <DatumContainer>
        <Key>Door Position:</Key>{' '}
        <Value>{getNumericalValue(props.doorPosition, 2)}</Value>
      </DatumContainer>
      <DatumContainer>
        <Key>Door Open/Closed:</Key>{' '}
        <Value>
          {' '}
          {getBoolean(props.isDoorClosed) === true &&
          getBoolean(props.isDoorOpen) === false
            ? 'CLOSED'
            : getBoolean(props.isDoorOpen) === true &&
              getBoolean(props.isDoorClosed) === false
            ? 'OPEN'
            : 'MU'}
        </Value>
      </DatumContainer>
      <InspectionDataDivider />
      <DatumContainer>
        <FlexBarKey>Gantry Position: </FlexBarKey>
        <FlexBarValue>
          X: {getNumericalValue(props.gantryPositionX, 0)}
        </FlexBarValue>
        <FlexBarValue>
          Y: {getNumericalValue(props.gantryPositionY, 0)}
        </FlexBarValue>
        <FlexBarValue>
          Z: {getNumericalValue(props.gantryPositionZ, 0)}
        </FlexBarValue>
      </DatumContainer>
    </TierDataContainer>
  );
};
export default TierData;
